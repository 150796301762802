import { Label } from "layout/typography/Label";
import { AutoComplete } from "primereact/autocomplete";
import styled from "styled-components";

const Title = styled.h3`
  margin-bottom: 16px;
`;

const StyledAutoComplete = styled(AutoComplete)`
  .p-autocomplete-multiple-container {
    width: 100%;
  }
`;

export function ProviderAndPayerSection({
  filteredProviders,
  filteredPayers,
  selectedProviders,
  selectedPayers,
  onPayerSearch,
  onProviderSearch,
  onProviderSelect,
  onProviderUnselect,
  onPayerSelect,
  onPayerUnselect,
}: {
  filteredProviders: { name: string; value: string }[];
  selectedProviders: { name: string; value: string }[];
  selectedPayers: { name: string; value: number }[];
  filteredPayers: { name: string; value: number }[];
  onPayerSearch: (e: any) => void;
  onProviderSearch: (e: any) => void;
  onProviderSelect: (e: any) => void;
  onProviderUnselect: (e: any) => void;
  onPayerSelect: (e: any) => void;
  onPayerUnselect: (e: any) => void;
}) {
  return (
    <div className="block bg-white border-round p-4 mb-4">
      <Title>Providers/Payers</Title>
      <div className="formgrid grid">
        <div className="field col-6">
          <Label htmlFor="hospitalName">Providers</Label>
          <StyledAutoComplete
            suggestions={filteredProviders}
            dropdown
            multiple
            field="label"
            className="w-full"
            value={selectedProviders}
            completeMethod={onProviderSearch}
            onSelect={(e) => onProviderSelect(e)}
            onUnselect={(e) => onProviderUnselect(e)}
          />
        </div>
        <div className="field col-6">
          <Label htmlFor="hospitalName">Payers</Label>
          <StyledAutoComplete
            suggestions={filteredPayers}
            dropdown
            multiple
            field="name"
            className="w-full"
            value={selectedPayers}
            completeMethod={onPayerSearch}
            onSelect={(e) => onPayerSelect(e)}
            onUnselect={(e) => onPayerUnselect(e)}
          />
        </div>
      </div>
    </div>
  );
}
