import hipaaImage from "assets/images/hipaa.svg";
import { useLoading } from "context/LoadingContext";
import { Outlet } from "react-router-dom";

import { Loading } from "../Loading";
import { Navbar } from "../Navbar";
import { ContentContainer, ImageContainer, StyledImage } from "./styled";

export function PublicLayout() {
  const { isLoading } = useLoading();

  return (
    <div className="flex flex-column w-full h-full justify-content-between">
      <Navbar variant="unlogged" />
      <ContentContainer className="flex flex-column mt-8 align-items-center">
        {isLoading && <Loading />}
        <Outlet />
      </ContentContainer>
      <ImageContainer className="flex align-items-center justify-content-between bottom-0 p-3 mt-3 bg-white">
        <div className="text-center">
          <StyledImage src="/logo_footer_gray.png" alt="Clear Health Logo" />
        </div>
        <StyledImage src={hipaaImage as string} alt="Hipaa Compliant" />
      </ImageContainer>
    </div>
  );
}
