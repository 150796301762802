import { IPayment, IUpdatePayment } from "types/Payment";

import { publicApi, api } from ".";

const publicApiPaymentQueries = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    updatePaymentHospitalVerification: builder.mutation<
      IPayment,
      IUpdatePayment
    >({
      query: ({ paymentId, hospitalCheck }) => ({
        method: "PUT",
        url: `/payment/${paymentId}`,
        body: { hospitalVerification: hospitalCheck },
      }),
    }),
    updatePaymentMethod: builder.mutation({
      query: ({ orderId, paymentMethod }) => ({
        method: "PATCH",
        url: `/payment/method/order/${orderId}`,
        body: { paymentMethod },
      }),
    }),
  }),
});

const apiPaymentQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    markPaymentReadyForTransfers: builder.mutation<boolean, string>({
      query: (paymentId: string) => ({
        url: `payment/${paymentId}/mark_ready_for_transfers`,
        method: "PATCH",
        body: {},
      }),
    }),
    markOrderBlockedTransfers: builder.mutation<boolean, string>({
      query: (orderId: string) => ({
        url: `payment/${orderId}/mark_block_transfer`,
        method: "PATCH",
        body: {},
      }),
    }),
  }),
});

export const {
  useUpdatePaymentHospitalVerificationMutation,
  useMarkPaymentReadyForTransfersMutation,
  useMarkOrderBlockedTransfersMutation,
  useUpdatePaymentMethodMutation,
} = { ...publicApiPaymentQueries, ...apiPaymentQueries };
