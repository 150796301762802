import { api } from ".";

const patientQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    notify: builder.mutation<
      any,
      {
        orderId: string;
        isResend?: boolean;
        customText?: string;
        onlyText?: boolean;
      }
    >({
      query: ({ orderId, isResend, customText, onlyText }) => ({
        method: "POST",
        url: `/communications/${orderId}/notify`,
        body: {
          isResend,
          customText: customText?.trim()?.length ? customText : undefined,
          onlyText,
        },
      }),
    }),
    internalNotification: builder.mutation<
      any,
      { orderId: string; internalEvent: string }
    >({
      query: ({
        orderId,
        internalEvent,
      }: {
        orderId: string;
        internalEvent: string;
      }) => ({
        method: "POST",
        url: `/communications/${orderId}/internal-event`,
        body: { internalEvent },
      }),
    }),
  }),
});

export const { useNotifyMutation, useInternalNotificationMutation } =
  patientQueries;
