import { api } from ".";

const dashboardQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    getPaidOrdersReport: builder.query<
      any,
      { accountId?: string; paidDate: string[] }
    >({
      query: ({ accountId, paidDate }) => ({
        url: `/dashboard/paidOrdersReport`,
        params: { accountId, paidDate },
      }),
    }),
    getAllOrdersReport: builder.query<
      any,
      { accountId?: string; createdDate: string[] }
    >({
      query: ({ accountId, createdDate }) => ({
        url: `/dashboard/allOrdersReport`,
        params: { accountId, createdDate },
      }),
    }),
  }),
});

export const { useGetPaidOrdersReportQuery, useGetAllOrdersReportQuery } =
  dashboardQueries;
