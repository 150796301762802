import { IAccount } from "types/Account/Account";

import { IProviderProcedure } from "../ProviderProcedure";

export enum SpecialtyList {
  FACILITY = "FACILITY",
  ANESTHESIOLOGIST = "ANESTHESIOLOGIST",
  PATHOLOGIST = "PATHOLOGIST",
  PRIMARY_PROVIDER = "PRIMARY_PROVIDER",
  ASSISTANT_PROVIDER = "ASSISTANT_PROVIDER",
}

export const SpecialtyListDict = {
  [SpecialtyList.FACILITY]: "Facility",
  [SpecialtyList.ANESTHESIOLOGIST]: "Anesthesiologist",
  [SpecialtyList.PATHOLOGIST]: "Pathologist",
  [SpecialtyList.PRIMARY_PROVIDER]: "Provider",
  [SpecialtyList.ASSISTANT_PROVIDER]: "Assistant Provider",
};

export interface IProvider {
  id: string;
  name: string;
  specialty: SpecialtyList;
  classification: string;
  stripeAccountNumber: string | null;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: number;
  country: string | null;
  rcm: string;
  npi: string;
  taxPayorIdNumber: string;
  phoneNumber: string;
  email: string;
  mainFacility: boolean;
  account: IAccount;
  reconciliationConfigId: string | null;
  providerProcedures: IProviderProcedure[];
  emailToReceiveNotification: string[];
  frontDeskEmployeesToReceiveDailyEmail: string[];
  emailToReceiveReports: string[];
  expirationDays: number;
  paymentContact: {
    contactEmail: string;
    contactName: string;
    contactPhone: string;
  };
  unitHospitalNumber: string | null;
  createdAt: string;
  updatedAt: string;
  publicContactName: string;
  publicContactRole: string;
  publicContactPhone: string;
  eligibilityEnabled: boolean;
  numberOfProceduresLive?: number;
  quantity: number;
}

export interface IEligibilityProvider {
  firstName?: string;
  middleName?: string;
  lastName: string;
  npi: string;
}

export interface IEligibilityMember {
  firstName: string;
  lastName: string;
  dob: string;
  memberID: string;
}
