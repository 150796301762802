import { Text, View } from "@react-pdf/renderer";

export function V3Disclaimer() {
  return (
    <View
      style={{
        fontFamily: "Helvetica",
        marginLeft: "12px",
        marginRight: "12px",
        marginBottom: 4,
        marginTop: 0,
        display: "flex",
        flexDirection: "column",
        fontSize: 11,
      }}
    >
      <Text
        style={{
          fontFamily: "Helvetica-Bold",
          marginBottom: 8,
          textDecoration: "underline",
          textAlign: "center",
        }}
      >
        Important disclaimer
      </Text>
      <Text
        style={{
          fontFamily: "Helvetica-Bold",
          marginBottom: 8,
        }}
      >
        We have estimated your surgery to be 3 hours, however, the actual time
        of surgery may vary.
      </Text>
      <Text
        style={{
          marginBottom: 8,
        }}
      >
        The total out-of-pocket cosmetic costs for the following fees are due as
        follows:
      </Text>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: 12,
        }}
      >
        <Text style={{ marginBottom: 4 }}>
          1.{" "}
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
            }}
          >
            Surgeon Fees
          </Text>
          – Payment is due{" "}
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
            }}
          >
            two weeks before your surgery date.
          </Text>
        </Text>
        <Text style={{ marginBottom: 4 }}>
          2.{" "}
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
            }}
          >
            Anesthesia Fees
          </Text>
          - Payment is due{" "}
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
            }}
          >
            two weeks before your surgery date.
          </Text>
        </Text>
        <Text style={{ marginBottom: 4 }}>
          3.{" "}
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
            }}
          >
            Facility Fees
          </Text>
          – Payment is due{" "}
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
            }}
          >
            in full on the day of surgery,
          </Text>{" "}
          paid directly to the surgery facility.
        </Text>
      </View>
    </View>
  );
}
