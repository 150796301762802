import { orderTypeConstants } from "constants/order";
import dayjs from "dayjs";
import { centsToDollars, parsePrice } from "helpers/price";
import { useMemo } from "react";

import {
  StyledPriceContainer,
  HorizontalLine,
  StyledTimeline,
} from "../styled";

function PaymentPlan({
  orderInfo,
  fullPrice,
  clearPrice,
}: {
  orderInfo: any;
  fullPrice: number;
  clearPrice: number;
}) {
  const estimatedCost = useMemo(() => {
    const orderTypeAmountMap = new Map<string, number>([
      [orderTypeConstants.BARIATRICS, clearPrice],
      [orderTypeConstants.BUNDLED, clearPrice],
      [orderTypeConstants.PATIENT_RESPONSIBILITY, fullPrice],
      [orderTypeConstants.GFE, fullPrice],
      [orderTypeConstants.ED, clearPrice],
    ]);
    const estimatedCost = orderTypeAmountMap.get(orderInfo.orderType) || 0;

    return estimatedCost;
  }, [fullPrice, clearPrice, orderInfo]);

  const initialPayment = useMemo(() => {
    const activePaymentPlan = orderInfo.paymentPlan.find(
      (plan: any) => plan.active
    );
    const percentage = activePaymentPlan.paymentPlanMinDownPaymentPercent;
    const flatAmount = activePaymentPlan.paymentPlanMinDownPaymentFlat;
    if (flatAmount) {
      return centsToDollars(flatAmount);
    }
    return estimatedCost * (percentage / 100);
  }, [estimatedCost, orderInfo]);

  const paymentPlanDuration = useMemo(() => {
    const activePaymentPlan = orderInfo.paymentPlan.find(
      (plan: any) => plan.active
    );
    return activePaymentPlan.paymentPlanDuration;
  }, [orderInfo]);

  const monthlyPayment = useMemo(() => {
    const remainingAmount = estimatedCost - initialPayment;
    return Math.floor((remainingAmount / paymentPlanDuration) * 100) / 100;
  }, [estimatedCost, initialPayment, paymentPlanDuration]);

  const paymentsToShow = useMemo(() => {
    const remainingAmount = estimatedCost - initialPayment;
    const basePayment =
      Math.floor((remainingAmount / paymentPlanDuration) * 100) / 100;
    const lastPayment =
      remainingAmount - basePayment * (paymentPlanDuration - 1);

    const installments = Array.from(
      { length: paymentPlanDuration },
      (_, index) => {
        const date = dayjs()
          .add(index + 1, "month")
          .format("YYYY-MM-DD");
        const payment =
          index === paymentPlanDuration - 1 ? lastPayment : basePayment;

        return { date, payment };
      }
    );
    const initialPaymentObj = {
      date: "Due Today",
      payment: initialPayment,
    };
    return [initialPaymentObj, ...installments];
  }, [orderInfo, initialPayment]);

  const generateContent = (item: any, index: any) => {
    return (
      <div className="w-full flex justify-content-between">
        <p
          className={`text-sm font-semibold line-height-2 ${
            index === 0 ? "purple" : "highlighted"
          }`}
        >
          {item.date}
        </p>
        <p className="text-base font-medium text-right line-height-1">
          {parsePrice(item.payment)}
        </p>
      </div>
    );
  };

  return (
    <div className="flex flex-column mt-4">
      <div className="flex gap-2 w-full justify-content-center">
        <i className="pi pi-info-circle light-green" />
        <h3 className="text-sm light-green">
          <span className="sensitive">{orderInfo.patient.firstName},</span>{" "}
          You’ve been approved for $0 fee, 0% Interest Payment Plan!
        </h3>
      </div>
      <StyledPriceContainer className="mt-4 gap-3 flex-column">
        <div className="flex justify-content-between align-items-center w-full">
          <p className="text-lg highlighted font-semibold line-height-3">
            Due Today
          </p>
          <p className="text-xl purple font-semibold text-right line-height-4">
            {parsePrice(initialPayment)}
          </p>
        </div>
        <HorizontalLine />
        <div className="flex flex-column gap-1">
          <p className="text-base font-medium line-height-3 highlighted">
            + {parsePrice(monthlyPayment)} monthly payments
          </p>
          <p className="font-normal text-sm line-height-3 ml-3">
            {paymentPlanDuration} payments
          </p>
        </div>
        <StyledTimeline value={paymentsToShow} content={generateContent} />
        <HorizontalLine className="mt-2" />
        <div className="flex justify-content-between mb-2 mt-2">
          <p className="text-sm line-height-2 font-normal">No Interest</p>
          <p className="text-sm line-height-2 font-normal">
            Total Cost {parsePrice(estimatedCost)}
          </p>
        </div>
      </StyledPriceContainer>
    </div>
  );
}

export default PaymentPlan;
