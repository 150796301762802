import { orderTypeConstants } from "constants/order";
import dayjs from "dayjs";
import { parseDate } from "helpers/date";
import { centsToDollars, parsePrice } from "helpers/price";
import { Button } from "primereact/button";
import { useMemo } from "react";

import { PriceWrapper, StyledTimeline } from "../styled";

export default function PaymentPlan({
  orderInfo,
  fullPrice,
  clearPrice,
  onShowCardDetails,
}: {
  orderInfo: any;
  fullPrice: number;
  clearPrice: number;
  onShowCardDetails: () => void;
}) {
  const estimatedCost = useMemo(() => {
    const orderTypeAmountMap = new Map<string, number>([
      [orderTypeConstants.BARIATRICS, clearPrice],
      [orderTypeConstants.BUNDLED, clearPrice],
      [orderTypeConstants.PATIENT_RESPONSIBILITY, fullPrice],
      [orderTypeConstants.GFE, fullPrice],
      [orderTypeConstants.ED, clearPrice],
    ]);
    const estimatedCost = orderTypeAmountMap.get(orderInfo.orderType) || 0;

    return estimatedCost;
  }, [fullPrice, clearPrice, orderInfo]);

  const activePaymentPlan = useMemo(() => {
    return orderInfo?.paymentPlan?.find((plan: any) => plan.active);
  }, [orderInfo]);

  const initialPayment = useMemo(() => {
    const percentage = activePaymentPlan.paymentPlanMinDownPaymentPercent;
    return estimatedCost * (percentage / 100);
  }, [estimatedCost, activePaymentPlan]);

  const installments = useMemo(() => {
    return [...activePaymentPlan.installments].sort((a: any, b: any) => {
      const dateA = dayjs(a.dateDue).valueOf();
      const dateB = dayjs(b.dateDue).valueOf();
      return dateA - dateB;
    });
  }, [activePaymentPlan]);

  const paymentsToShow = useMemo(() => {
    const initialPaymendDone = {
      amount: parsePrice(initialPayment),
      status: "Paid",
      date: parseDate(activePaymentPlan.createdAt),
      label: "Initial Payment",
      icon: "pi pi-plus",
    };
    const paymentPlanInstallments = installments.map(
      (installment: any, index: number) => {
        return {
          amount: parsePrice(centsToDollars(installment.amountInCents)),
          status: installment.paymentId ? "Paid" : "Not Paid",
          date: parseDate(installment.dateDue),
          label: "Auto Payment",
          icon:
            index !== installments.length - 1 ? "pi pi-dollar" : "pi pi-check",
        };
      }
    );
    return [initialPaymendDone, ...paymentPlanInstallments];
  }, [orderInfo, activePaymentPlan, installments]);

  const customizedMarker = (item: any) => {
    return (
      <span
        className="flex align-items-center justify-content-center text-white border-circle z-1"
        style={{
          backgroundColor: item.status === "Paid" ? "#584dfd" : "white",
          border: `2px solid ${item.status === "Paid" ? "#584dfd" : "#d0ccfe"}`,
          width: "25px",
          minWidth: "25px",
          height: "25px",
          boxShadow:
            item.status === "Paid"
              ? "0px 4px 5px -1px rgba(0, 0, 0, 0.18) !important"
              : "",
        }}
      >
        <i
          className={item.icon}
          style={{
            color: item.status === "Paid" ? "white" : "#d0ccfe",
            fontSize: "12px",
          }}
        />
      </span>
    );
  };

  const customizedContent = (item: any) => {
    const isPaid = item.status === "Paid";
    return (
      <div className="flex justify-content-between">
        <div className="flex flex-column">
          <p
            style={{ color: isPaid ? "#584dfd" : "#17143f" }}
            className="font-semibold mt-1"
          >
            {item.label}
          </p>
          <p className="text-xs" style={{ color: "#706f7b" }}>
            {item.date}
          </p>
        </div>
        <p className="text-base font-medium">{item.amount}</p>
      </div>
    );
  };

  return (
    <div className="pt-2 flex flex-column">
      <p className="purple font-bold text-md text-right mt-2 mb-3">
        Payment Plan Details
      </p>
      <PriceWrapper className="flex justify-content-between w-full mb-4">
        <h2 className="text-lg font-semibold my-2">Total Price</h2>
        <p className="text-2xl purple font-bold text-right align-content-center">
          {parsePrice(estimatedCost)}
        </p>
      </PriceWrapper>
      <StyledTimeline
        value={paymentsToShow}
        marker={(item) => customizedMarker(item)}
        content={(item) => customizedContent(item)}
      />
      <Button
        className="justify-content-center my-2 w-fit align-self-center "
        onClick={onShowCardDetails}
      >
        <span className="font-bold">Update Card Details</span>
      </Button>
    </div>
  );
}
