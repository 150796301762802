import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { Tag } from "primereact/tag";
import { Timeline } from "primereact/timeline";
import styled from "styled-components";

interface IStyledPageContainerProps {
  maxWidth?: string;
  minWidth?: string;
}

export const HorizontalLine = styled.hr`
  border-top: 1px solid #e2e2e4;
`;

export const StyledPageContainer = styled.div<IStyledPageContainerProps>`
  max-width: ${({ maxWidth }) => maxWidth || "672px"};
  min-width: ${({ minWidth }) => minWidth || "672px"};
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 48px;

  p {
    color: #706f7b;
  }
  .purple {
    color: #4f46e5;
  }
  .green {
    color: #027a48;
  }
  .light-green {
    color: #07a685;
  }
  .red {
    color: #d92d20;
  }
  .highlighted {
    color: #17143f;
  }
  @media (max-width: 672px) {
    min-width: 100%;
    width: 100%;
    margin-top: 20px;
  }
`;

export const StyledSectionContainer = styled.div`
  border: 1px solid #e2e2e4;
  border-radius: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 16px;
  .gray {
    color: #858490;
  }
  .shadow {
    box-shadow: 0px 5px 10px 0px rgba(18, 12, 96, 0.06),
      0px 2px 2px 0px rgba(17, 12, 96, 0.1);
  }
`;

export const StyledButtonTabContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 4px;
  border: 1px solid #d0ccfe;
  border-radius: 10px;
  background-color: #f6f8fe;
  margin-top: 24px;
  margin-bottom: 12px;
`;

export const StyledSelectButton = styled(SelectButton)`
  margin-top: 24px;
  width: 100%;
  padding: 4px;
  border: 1px solid #d0ccfe;
  background-color: #f6f8fe;
  border-radius: 10px;
  .p-button {
    vertical-align: middle;
  }
  .p-component {
    width: 50%;
    border: none;
    background: transparent;
    color: #a5a4ad;
    font-size: 14px;
    font-weigth: 600;
    line-height: 20px;
    :hover {
      background: transparent;
    }
  }
  .p-selectbutton .p-button.p-highlight {
    border-radius: 6px !important;
  }
  .p-button:first-of-type,
  .p-button:last-of-type {
    border-radius: 6px;
    padding-left: 7px;
    padding-right: 7px;
  }
  .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: transparent !important;
    border: none;
    color: #a5a4ad;
  }

  .p-component.p-highlight .p-tag.p-tag-success {
    background-color: #ecfdf3 !important;
    color: #027a48;
    border-color: #ecfdf3;
  }

  .p-tag.p-tag-success {
    background-color: #ececed !important;
    border-radius: 16px;
    padding: 2px 8px 2px 8px;
    width: fit-content;
    color: #a5a4ad;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    border: 1px solid #ececed;
  }
`;

export const StyledPriceContainer = styled.div`
  background: #f6f8fe;
  border-radius: 12px;
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  gap: 30px;
  @media (max-width: 640px) {
    gap: 25px;
  }
`;

export const StyledTimeline = styled(Timeline)`
  font-size: 14px !important;
  max-height: 250px;
  overflow-y: auto;
  .p-timeline-event-opposite {
    display: none;
  }
  .p-timeline-event-content {
    padding-top: 0px !important;
    padding-right: 0px !important;
  }
  .p-timeline-event {
    min-height: 50px;
  }
  .p-timeline-event-connector {
    background-color: #e2e2e4;
  }
  .p-timeline-event-marker {
    border-color: #d0ccfe;
    background-color: transparent;
    width: 20px;
    height: 20px;
  }
  .p-timeline-event:first-child .p-timeline-event-marker {
    border-color: #f6f8fe;
    box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.18);
    background-color: #584dfd;
    position: relative;
    border-width: 1.5px;
  }
  .p-timeline-event:first-child .p-timeline-event-marker::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7px;
    height: 7px;
    background-color: #f6f8fe;
    border-radius: 50%;
  }
`;

export const StyledTag = styled(Tag)`
  .p-tag-value {
    liine-height: 1;
  }
`;

export const StyledButton = styled(Button)`
  gap: ${sizer(2)};
  margin-bottom: ${sizer(4)};

  span:first-child {
    font-size: ${sizer(3)};
  }
`;
