import { IProvider } from "types/Provider/Provider";

export function ServiceInfo({
  title,
  providers,
  quantity,
  code,
}: {
  title: string;
  providers: IProvider[];
  quantity: number;
  code: string;
}) {
  return (
    <div className="flex flex-column w-full mt-2">
      <h2 className="text-base line-height-3 font-semibold">{title}</h2>
      <p className="text-sm line-height-2 font-semibold">
        CPT: {code}
        {quantity && <span>, Qty:{quantity}</span>}
      </p>

      {providers.map((provider) => (
        <p className="text-sm line-height-2 font-normal">{provider.name}</p>
      ))}
    </div>
  );
}
