import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    fontFamily: "Helvetica",
    fontSize: "12px",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    color: "#000",
    position: "relative",
  },
  section: {
    margin: "12px 50px",
    fontFamily: "Helvetica",
  },
  text: {
    color: "#666",
    fontSize: "11px",
  },
  title: {
    fontSize: "16px",
    textAlign: "center",
  },
  header: {
    width: "100%",
    height: "100px",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  image: {
    width: "120px",
    marginTop: "5px",
  },
  addressContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    padding: 0,
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    textAlign: "justify",
    flex: 1,
  },
  bodyText: {
    fontSize: "11px",
    marginBottom: "8px",
  },
  bodyTextBold: {
    fontSize: "11px",
    marginBottom: "8px",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  list: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  itemList: {
    fontSize: "10px",
    marginBottom: "4px",
  },
  smallText: {
    fontSize: "11px",
  },
  listTitle: {
    fontFamily: "Helvetica-Bold",
    fontSize: "11px",
  },
  centralizeView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
  },

  // TABLE
  table: {
    display: "flex",
    flexDirection: "column",
    width: "400px",
    marginBottom: 8,
    fontSize: "11px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    padding: 5,
  },
  rightCell: {
    width: 100,
    textAlign: "right",
  },
  rightCellBorder: { borderLeft: "1px solid #000" },
  leftCell: {
    width: 300,
    textAlign: "left",
  },
  grayBackground: {
    backgroundColor: "#c1e4f5",
  },
  headerRow: {
    fontFamily: "Helvetica-Bold",
    paddingBottom: 0,
    paddingRight: 5,
    paddingLeft: 5,
    borderBottom: "1px solid #505050",
  },
  // END TABLE
});
