import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { setRows } from "store/slices/serviceDetails";
import { ProcedureRow } from "types/CreateOrder";

interface IProviderDropdown {
  row: ProcedureRow;
  currentProviderProcedures?: any;
}
export function ProviderDropdown({
  row,
  currentProviderProcedures,
}: IProviderDropdown) {
  const dispatch = useDispatch();
  const rows = useSelector((state: any) => state.serviceDetailsSlice.rows);

  const { providerDropdown, selectedProvider, id } = row;

  if (providerDropdown.length === 1) {
    return <span>{selectedProvider.name}</span>;
  }

  const handleOnChangeProviderDropdown = (e: DropdownChangeParams) => {
    const newRows = rows.slice() as ProcedureRow[];

    const provider = providerDropdown.find(
      (provider) => provider.id === e.value
    );
    const providerProcedure = currentProviderProcedures?.find(
      (pp: any) =>
        pp.providerId === e.value && pp.procedureId === row.procedureId
    );
    if (provider) {
      const updatedRows = newRows.map((r) => {
        if (
          r.procedureId === row.procedureId &&
          r.cptCode === row.cptCode &&
          r.providerProcedureId === row.providerProcedureId &&
          JSON.stringify(
            [...r.providerDropdown].sort((a, b) => a.id.localeCompare(b.id))
          ) ===
            JSON.stringify(
              [...row.providerDropdown].sort((a, b) => a.id.localeCompare(b.id))
            ) &&
          JSON.stringify(r.selectedProvider) ===
            JSON.stringify(row.selectedProvider)
        ) {
          return {
            ...r,
            selectedProvider: provider,
            amount: provider.priceInCents,
            providerProcedureId:
              providerProcedure?.id || row.providerProcedureId,
            toBeEdited:
              providerProcedure?.toBeEdited === undefined
                ? null
                : providerProcedure?.toBeEdited,
          };
        }
        return r;
      });
      dispatch(setRows(updatedRows));
    }
  };

  return (
    <Dropdown
      data-testid="providerDropdown_ServiceDetails"
      optionValue="id"
      optionLabel="name"
      id="selectProvider"
      options={providerDropdown}
      value={selectedProvider.id}
      placeholder="Select Provider"
      onChange={handleOnChangeProviderDropdown}
    />
  );
}
