import { validationConstants } from "constants/formValidation";
import { orderTypeConstants, orderStatusConstants } from "constants/order";
import { ToastContext } from "context/ToastContext";
import { parseDate } from "helpers/date";
import useFormValidation from "hooks/useFormValidation";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import {
  removeTimezone,
  validateInputDatePicker,
} from "pages/CreateOrder/utils/helpers";
import {
  handleChangeDateWithMask,
  handleChangePhoneWithMask,
} from "pages/CreateOrder/utils/mask";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useContext, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useNotifyMutation } from "store/queries/communications";
import { useUpdateOrderMutation } from "store/queries/order";
import { useUpdatePatientOverviewMutation } from "store/queries/patient";
import styled from "styled-components";
import { IDefaultServiceDetails, IOrder } from "types/Order/Order";

import { CustomTextDialog } from "./components/CustomTextDialog";

const StyledCard = styled(Card)`
  margin-bottom: ${sizer(2)};
`;

const StyledParagraph = styled.p<{
  outreachStopped: boolean | undefined | null;
}>`
  color: ${(props) =>
    props?.outreachStopped ? "var(--red-700)" : "var(--green-700)"} !important;
`;

interface IPatientEdit {
  email?: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber?: string;
  outOfPocketReason: string;
}

function PatientDetails({
  order: { patient, serviceDetails, orderType, id, status, outreachStopped },
  onUpdateOrder,
}: {
  order: IOrder;
  onUpdateOrder: () => void;
}) {
  const {
    control,
    trigger,
    getValues,
    setValue,
    setError,
    formState: { errors, dirtyFields },
  } = useFormContext<IPatientEdit>();
  const [editing, setEdit] = useState(false);
  const [outreachButtonLoading, setOutreachButtonLoading] = useState(false);
  const [showCustomTextDialog, setShowCustomTextDialog] = useState(false);
  const [customText, setCustomText] = useState("");
  const [updatePatient, { isError: isUpdatePatientError }] =
    useUpdatePatientOverviewMutation();
  const [notify] = useNotifyMutation();
  const [updateOrder] = useUpdateOrderMutation();
  const {
    isValidPhoneNumber,
    handleEmailPhoneValidation,
    getFormErrorMessage,
    handleOnBlurField,
  } = useFormValidation();
  const { current: toastElement } = useContext(ToastContext);

  useEffect(() => {
    setValue("email", patient.email);
    setValue("firstName", patient.firstName);
    setValue("lastName", patient.lastName);
    setValue("dateOfBirth", parseDate(patient.dateOfBirth));
    setValue("phoneNumber", patient.phoneNumber);
  }, [patient, setValue]);

  const resetForm = () => {
    setValue("email", patient.email);
    setValue("firstName", patient.firstName);
    setValue("lastName", patient.lastName);
    setValue("dateOfBirth", parseDate(patient.dateOfBirth));
    setValue("phoneNumber", patient.phoneNumber);
  };

  const mapPatientUpdateRequest = (values: IPatientEdit) => ({
    dateOfBirth: removeTimezone(values.dateOfBirth),
    email: values.email,
    firstName: values.firstName,
    lastName: values.lastName,
    phoneNumber: values.phoneNumber,
  });

  const submit = () => {
    const values = mapPatientUpdateRequest(getValues());
    const EmailOrPhoneChanged =
      dirtyFields.email || dirtyFields.phoneNumber || false;

    updatePatient({ id: patient.id, data: values })
      .unwrap()
      .then(async () => {
        if (EmailOrPhoneChanged) {
          notify({ orderId: id })
            .unwrap()
            .then(() => {
              toastElement?.show({
                summary: "Success",
                severity: "success",
                detail: "Patient edited successfully",
              });
            })
            .catch(() => {
              toastElement?.show({
                severity: "error",
                detail: "Try again later.",
                summary: "Email failed to be sent. Please try again.",
              });
            });
        } else {
          toastElement?.show({
            summary: "Success",
            severity: "success",
            detail: "Patient edited successfully",
          });
        }
        setEdit(false);
      })
      .catch(() => {
        if (isUpdatePatientError) {
          toastElement?.show({
            summary: "Error",
            severity: "error",
            detail: "Patient cannot be edited. Please try again.",
          });
        }
      });
  };

  const actionFooter = () => {
    return (
      <>
        <Button label="Save" icon="pi pi-check" onClick={submit} />
        <Button
          label="Cancel"
          onClick={() => {
            resetForm();
            setEdit(false);
          }}
          icon="pi pi-times"
          style={{ marginLeft: "0.5em" }}
        />
      </>
    );
  };

  const enableEditing = () => {
    setEdit(true);
  };

  const handleResendNotification = () => {
    notify({ orderId: id, isResend: true })
      .unwrap()
      .then(() => {
        toastElement?.show({
          summary: "Success",
          severity: "success",
          detail: "Notification resent successfully",
        });
      })
      .catch(() => {
        toastElement?.show({
          summary: "Error",
          severity: "error",
          detail: "Failed to resend notification. Please try again.",
        });
      });
  };

  const handleChangeOutreach = () => {
    setOutreachButtonLoading(true);
    updateOrder({ id, outreachStopped: !outreachStopped })
      .unwrap()
      .then(() => {
        onUpdateOrder();
        setOutreachButtonLoading(false);
        toastElement?.show({
          summary: "Success!",
          severity: "success",
          detail: "Your order has been updated.",
        });
        setEdit(false);
      })
      .catch(() => {
        toastElement?.show({
          severity: "error",
          detail: "Try again later.",
          summary: "Something went wrong.",
        });
      });
  };

  const handleSendCustomText = () => {
    notify({ orderId: id, isResend: false, customText, onlyText: true })
      .unwrap()
      .then(() => {
        toastElement?.show({
          summary: "Success",
          severity: "success",
          detail: "Notification resent successfully",
        });
      })
      .catch(() => {
        toastElement?.show({
          summary: "Error",
          severity: "error",
          detail: "Failed to resend notification. Please try again.",
        });
      });
  };

  const actionHeader = () => {
    return (
      <div className="flex col-12 justify-content-between">
        <StyledParagraph
          data-testid="outreachStopped"
          outreachStopped={outreachStopped}
        >
          {outreachStopped ? "Outreach Stopped" : "Outreach Enabled"}
        </StyledParagraph>
        <div className="flex gap-2">
          <Button
            label={outreachStopped ? "Enable outreach" : "Stop outreach"}
            onClick={handleChangeOutreach}
            loading={outreachButtonLoading}
            className="h-fit"
          />
          {status === orderStatusConstants.SENT_TO_PATIENT && (
            <Button
              label="Resend Notification"
              onClick={handleResendNotification}
              className="h-fit"
            />
          )}
          {patient.preferredContactMethod !== "Email" && (
            <Button
              label="Send Custom Text"
              onClick={() => setShowCustomTextDialog(true)}
              className="h-fit"
            />
          )}
          <Button
            label="Edit"
            icon="pi pi-check"
            className="h-fit"
            onClick={enableEditing}
          />
        </div>
      </div>
    );
  };

  const isDefaultOrder =
    orderType === orderTypeConstants.BUNDLED ||
    orderType === orderTypeConstants.BARIATRICS ||
    orderType === orderTypeConstants.ED;

  return (
    <>
      <CustomTextDialog
        showCustomTextDialog={showCustomTextDialog}
        setShowCustomTextDialog={setShowCustomTextDialog}
        customText={customText}
        setCustomText={setCustomText}
        onSendCustomText={handleSendCustomText}
      />
      <StyledCard
        title="Patient Details"
        footer={editing && actionFooter}
        header={!editing && actionHeader}
      >
        <div className="formgrid grid w-100">
          <div className="field flex flex-column col-12 md:col-4">
            <Label htmlFor="firstName">First Name</Label>
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: validationConstants.PATIENT_INFO_FORM.FIRST_NAME,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    disabled={!editing}
                    id="firstName"
                    aria-labelledby="firstName"
                    value={value}
                    onChange={onChange}
                    onBlur={() => {
                      handleOnBlurField({
                        onBlur,
                        field: "firstName",
                        trigger,
                      });
                    }}
                  />
                  {getFormErrorMessage("firstName", errors)}
                </>
              )}
            />
          </div>
          <div className="field flex flex-column col-12 md:col-4">
            <Label htmlFor="lastName">Last Name</Label>
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: validationConstants.PATIENT_INFO_FORM.LAST_NAME,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    disabled={!editing}
                    id="lastName"
                    aria-labelledby="lastName"
                    value={value}
                    onChange={onChange}
                    onBlur={() => {
                      handleOnBlurField({ onBlur, field: "lastName", trigger });
                    }}
                  />
                  {getFormErrorMessage("lastName", errors)}
                </>
              )}
            />
          </div>

          <div className="field flex flex-column col-12 md:col-4">
            <Label htmlFor="email">Email</Label>
            <Controller
              name="email"
              control={control}
              rules={{
                required: handleEmailPhoneValidation(
                  patient.preferredContactMethod,
                  "email"
                ),
                pattern: {
                  value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  message: "Email is invalid.",
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    disabled={!editing}
                    id="email"
                    aria-labelledby="email"
                    value={value}
                    onChange={onChange}
                    onBlur={() => {
                      handleOnBlurField({ onBlur, field: "email", trigger });
                    }}
                  />
                  {getFormErrorMessage("email", errors)}
                </>
              )}
            />
          </div>

          <div className="field flex flex-column col-12 md:col-4">
            <Label htmlFor="dob">DOB</Label>
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{
                validate: (date) => validateInputDatePicker(date, "past"),
                required: validationConstants.PATIENT_INFO_FORM.DOB,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    disabled={!editing}
                    value={value}
                    id="dateOfBirth"
                    placeholder="99/99/9999"
                    onBlur={() =>
                      handleOnBlurField({
                        onBlur,
                        field: "dateOfBirth",
                        trigger,
                      })
                    }
                    onChange={(e) => handleChangeDateWithMask(e, onChange)}
                  />

                  {getFormErrorMessage("dateOfBirth", errors)}
                </>
              )}
            />
          </div>

          <div className="field flex flex-column col-12 md:col-4">
            <Label htmlFor="phoneNumber">Phone Number</Label>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: handleEmailPhoneValidation(
                  patient.preferredContactMethod,
                  "phone"
                ),
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    disabled={!editing}
                    id="phoneNumber"
                    autoComplete="off"
                    aria-labelledby="phoneNumber"
                    value={value}
                    onChange={(e) => handleChangePhoneWithMask(e, onChange)}
                    onBlur={() => {
                      isValidPhoneNumber(
                        patient.preferredContactMethod,
                        value,
                        setError
                      );
                      return handleOnBlurField({
                        onBlur,
                        field: "phoneNumber",
                        trigger,
                      });
                    }}
                  />
                  {getFormErrorMessage("phoneNumber", errors)}
                </>
              )}
            />
          </div>

          {isDefaultOrder && (
            <div className="field flex flex-column col-12 md:col-4">
              <Label htmlFor="outOfPocketReason">Out of pocket reason</Label>

              <InputText
                disabled
                id="outOfPocketReason"
                aria-labelledby="outOfPocketReason"
                value={
                  (serviceDetails as IDefaultServiceDetails).outOfPocketReason
                }
              />
            </div>
          )}
        </div>
      </StyledCard>
    </>
  );
}

export default PatientDetails;
