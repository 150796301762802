import { orderStatusConstants } from "constants/order";
import { events } from "constants/tagManager";
import { useLoading } from "context/LoadingContext";
import { ToastContext } from "context/ToastContext";
import dayjs from "dayjs";
import { useAnalytics } from "hooks/useAnalytics";
import useDocumentTitle from "hooks/useDocumentTitle";
import { debounce, isEmpty } from "lodash";
import { handleChangeDateWithMask } from "pages/CreateOrder/utils/mask";
import { DOBAuthProps } from "pages/OrderOverview/utils/interfaces";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useLoginWithOrderIdAndDOBMutation } from "store/queries/auth";
import { useGetCustomsByOrderTypeAndAccountIdQuery } from "store/queries/customize";
import { useGetOrderOverviewQuery } from "store/queries/order";
import { onPublicTokenChange } from "store/slices/auth";
import { onOrderOverviewStepChange } from "store/slices/order";
import { onUserAccountLoad } from "store/slices/user";
import styled from "styled-components";

import { OrderPaidSection } from "../OrderPaidSection";

const StyledContainerNew = styled.div`
  width: 588px;
  padding-left: 20px;
  padding-right: 20px;
  p {
    color: #706f7b;
  }
  .title {
    color: #17143f;
  }

  .highlighted {
    color: #17143f;
  }

  .purple {
    color: #4f46e5;
  }

  @media (max-width: 640px) {
    width: 100%;
    max-width: 588px;
  }
`;

const StyledInputText = styled(InputText)`
  padding-left: 40px;
  width: 100%;
`;

const StyledCalendarIcon = styled.i`
  position: absolute;
  left: 12px;
  top: 12px;
  font-size: 20px;
  color: #858490;
`;

const StyledPatientContainer = styled.div`
  width: 100%;
  border: 1px solid #7468fd;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: #f6f8fe;
  margin-bottom: 24px;
`;

export function DOBAuth({ variant }: DOBAuthProps) {
  useDocumentTitle("Authentication - Order Overview");

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pageViewEvents } = useAnalytics();
  const { current: toastElement } = useContext(ToastContext);

  const { setIsLoading } = useLoading();
  const [value, setValue] = useState<string>("");

  const { data, isError, isFetching } = useGetOrderOverviewQuery(id);
  const [login, { isLoading }] = useLoginWithOrderIdAndDOBMutation();

  const order = useMemo(() => data?.data, [data]);

  const { data: contentData, isFetching: isContentFetching } =
    useGetCustomsByOrderTypeAndAccountIdQuery(
      {
        orderType: order?.orderType || "",
        accountId: order?.accountId || "",
      },
      {
        skip: !order,
      }
    );

  const { dobPage: content } = contentData?.data || {};

  const debouncedPageViewEvents = debounce((order, event: string) => {
    pageViewEvents(
      {
        accountName: order?.accountName,
        orderType: order?.orderType,
        communicationMethod: order?.communicationMethod,
      },
      event
    );
  }, 300);

  useEffect(() => {
    setIsLoading(isFetching || isLoading || isContentFetching);
  }, [isFetching, isLoading, isContentFetching]);

  useEffect(() => {
    if (!id || isError) {
      navigate("/not-found");
    }
  }, [isError, id]);

  useEffect(() => {
    if (isEmpty(order)) return;

    if (
      order.status === orderStatusConstants.EXPIRED ||
      order.status === orderStatusConstants.CANCELED ||
      order.status === orderStatusConstants.NO_BALANCE_DUE ||
      order.status === orderStatusConstants.DRAFT
    ) {
      navigate("/not-found");
    }

    debouncedPageViewEvents(
      {
        accountName: order?.accountName,
        orderType: order?.orderType,
        communicationMethod: order?.patient.preferredContactMethod,
      },
      events.VIEW_VERIFY_PAGE
    );

    dispatch(
      onUserAccountLoad({
        accountLogoUrl: order.accountLogo,
        name: order.accountName,
      })
    );
  }, [order]);

  const dateTimeOfService = dayjs.utc(order?.dateOfService);
  const formatDateTimeOfService =
    dateTimeOfService.hour() !== 0 || dateTimeOfService.minute() !== 0
      ? "MMM D, YYYY hh:mm A"
      : "MMM D, YYYY";

  const handleOnClickToAuthenticate = () => {
    if (!id) return;

    const parsedDate = new Date(value).toISOString();

    debouncedPageViewEvents(
      {
        accountName: order?.accountName,
        orderType: order?.orderType,
        communicationMethod: order?.patient.preferredContactMethod,
      },
      events.CLICK_VERIFY_PAGE
    );

    login({ orderId: id, dateOfBirth: parsedDate })
      .unwrap()
      .then(({ token }) => {
        dispatch(onPublicTokenChange({ token }));
        dispatch(onOrderOverviewStepChange({ step: variant }));
      })
      .catch(() => {
        const detail =
          "Authentication failed. Please check your date of birth or order ID.";

        toastElement?.show({
          detail,
          summary: "Error",
          severity: "error",
        });
      });
  };

  if (isFetching || isLoading || isContentFetching) {
    return null;
  }
  if (
    order &&
    [orderStatusConstants.PAID, orderStatusConstants.PARTIALLY_PAID].includes(
      order.status
    )
  ) {
    return <OrderPaidSection status={order.status} />;
  }
  return (
    <div className="flex flex-column gap-4 w-full align-items-center">
      <StyledContainerNew className="flex flex-column md:mt-3 mb-auto">
        <p className="text-xs font-medium line-height-3">Notice from</p>
        <p className="text-lg font-medium line-height-3 font-semibold title mb-2">
          {order?.accountName}
        </p>
        <p className="text-sm font-normal line-height-3 mb-3">
          Regarding your appointment for:
        </p>
        <StyledPatientContainer>
          <p className="text-xs line-height-3 font-medium mb-1">PATIENT</p>
          <h2 className="font-semibold text-lg sensitive line-height-3 mb-1">
            {order?.patient.firstName} {order?.patient.lastName}
          </h2>
          <p className="text-xs line-height-3 font-medium mb-2 mt-2">
            VISIT DETAILS
          </p>
          <p className="font-medium text-sm line-height-2 highlighted ">
            {dateTimeOfService.format(formatDateTimeOfService)}
          </p>
          <p className="text-sm line-height-2 font-normal">{order?.address}</p>
        </StyledPatientContainer>
        <p className="purple text-lg font-semibold line-height-3">
          Verify Your Identitiy
        </p>
        <p className="text-sm line-height-3 font-normal mt-1 mb-4">
          {content?.description}
        </p>
        <p className="text-xs font-medium line-height-2 highlighted mb-2">
          Date of Birth
        </p>
        <div className="w-full relative">
          <StyledInputText
            value={value}
            id="dateOfBirth"
            placeholder="mm/dd/yyyy"
            onChange={(e) => handleChangeDateWithMask(e, setValue)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleOnClickToAuthenticate();
              }
            }}
          />
          <StyledCalendarIcon className="pi pi-calendar " />
        </div>
        <Button
          onClick={handleOnClickToAuthenticate}
          className="w-full justify-content-center mt-6 mb-3"
        >
          Verify
        </Button>
        <p className="gray text-center font-normal text-xs">
          Thousands of patients have used Clear to reduce their medical bills
          and you can too.
        </p>
      </StyledContainerNew>
    </div>
  );
}
