import {
  AutoComplete,
  AutoCompleteSelectParams,
} from "primereact/autocomplete";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { IProcedure } from "types/Procedure";
import { IProviderProcedure } from "types/ProviderProcedure";

import {
  buildFilteredProceduresSuggestions,
  buildSelectedProceduresObject,
} from "../../builders";
import {
  autoCompleteFieldHandleOnSelectProcedure,
  autoCompleteHandleOnUnSelectProcedureNew,
} from "../../handlers";

interface IAutoComplete {
  search: (e: any) => void;
  autoCompleteValue: string[];
  procedures: IProcedure[];
  providerProcedures: IProviderProcedure[];
  setCurrentProviderProcedures?: any;
  currentProviderProcedures?: IProviderProcedure[];
  disabled?: boolean;
}
export function AutoCompleteCPT({
  search,
  autoCompleteValue,
  procedures,
  providerProcedures,
  setCurrentProviderProcedures,
  currentProviderProcedures,
  disabled = false,
}: IAutoComplete) {
  const dispatch = useDispatch();
  const { rows, selectedProcedures } = useSelector(
    (state: RootState) => state.serviceDetailsSlice
  );

  const onSelectProcedure = (e: AutoCompleteSelectParams) => {
    const arrayOfCptCodes = [...selectedProcedures];
    const filteredCpt = arrayOfCptCodes.filter((p) => p === e.value);
    if (currentProviderProcedures && setCurrentProviderProcedures) {
      const copy = [...currentProviderProcedures];
      const existingIds = new Set(copy.map((pp) => pp.id));
      providerProcedures.forEach((procedure) => {
        if (!existingIds.has(procedure.id)) {
          copy.push(procedure);
        }
      });
      setCurrentProviderProcedures(copy);
    }

    autoCompleteFieldHandleOnSelectProcedure({
      e,
      dispatch,
      selectedProcedures,
      procedures,
      providerProcedures,
      rows,
      newValue: filteredCpt.length + 1,
    });
  };

  const onUnSelectProcedure = (e: AutoCompleteSelectParams) => {
    autoCompleteHandleOnUnSelectProcedureNew({
      e,
      autoCompleteValue,
      rows,
      dispatch,
      selectedProcedures,
    });
    const obj = buildSelectedProceduresObject([e.value]);
    const filteredProviderProcedures = currentProviderProcedures?.filter(
      (pp) => pp.procedure.cptCode !== obj[0].code
    );
    setCurrentProviderProcedures(filteredProviderProcedures);
  };

  const handleSuggestion = useMemo(() => {
    const suggestions = buildFilteredProceduresSuggestions(procedures);
    return suggestions;
  }, [procedures]);

  return (
    <>
      <label htmlFor="code">CPT code</label>
      <AutoComplete
        multiple
        id="code"
        dropdown
        disabled={disabled}
        dropdownMode="current"
        className="w-full"
        completeMethod={search}
        value={autoCompleteValue}
        data-testid="autocompleteCPT_ServiceDetails"
        placeholder="Select CPT Code..."
        suggestions={handleSuggestion}
        onSelect={onSelectProcedure}
        onUnselect={onUnSelectProcedure}
        virtualScrollerOptions={{ itemSize: 38 }}
        emptyMessage="No results found"
        minLength={1}
      />
    </>
  );
}
