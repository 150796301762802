import { ToastContext } from "context/ToastContext";
import { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useLoginWithOrderIdAndDOBMutation } from "store/queries/auth";
import { onPublicTokenChange } from "store/slices/auth";
import { orderSelector, onOrderUpdateStepChange } from "store/slices/order";

import { DOBAuth } from "./components/DOBAuth";
import { Update } from "./components/Update";
import { Steps } from "./utils/constants";

type OrderUpdateProps = {
  variant: Steps;
};

export function OrderUpdate({ variant }: OrderUpdateProps) {
  const { orderUpdateStep } = useSelector(orderSelector);
  const [isProcessing, setIsProcessing] = useState(true);
  const [login] = useLoginWithOrderIdAndDOBMutation();
  const { current: toastElement } = useContext(ToastContext);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const userType = localStorage.getItem("clear_userType");
    const dob = localStorage.getItem("clear_dob");
    if (userType && dob && id) {
      login({
        orderId: id || "",
        dateOfBirth: new Date(dob).toISOString(),
      })
        .unwrap()
        .then(({ token }: { token: string }) => {
          dispatch(onPublicTokenChange({ token }));
          dispatch(
            onOrderUpdateStepChange({
              step: Steps.ORDER_UPDATE,
            })
          );
          setIsProcessing(false);
          localStorage.removeItem("clear_userType");
          localStorage.removeItem("clear_dob");
        })
        .catch(() => {
          const detail =
            "Authentication failed. Please check your date of birth.";
          toastElement?.show({
            detail,
            summary: "Error",
            severity: "error",
          });
          setIsProcessing(false);
          localStorage.removeItem("clear_userType");
          localStorage.removeItem("clear_dob");
        });
    } else {
      setIsProcessing(false);
    }
  }, [id, login, dispatch, toastElement]);

  if (isProcessing) {
    return null;
  }

  switch (orderUpdateStep) {
    case Steps.ORDER_UPDATE:
      return <Update />;

    default:
      return <DOBAuth {...{ variant }} />;
  }
}
