import { filterUniqueObjects } from "helpers/object";
import { isEmpty, maxBy } from "lodash";
import { AutoCompleteSelectParams } from "primereact/autocomplete";
import {
  setAutoCompleteValue,
  setRows,
  setSelectedProcedures,
  updateRows,
  addSelectedProcedures,
  addAutoCompleteValue,
  removeRows,
} from "store/slices/serviceDetails";
import { ProcedureRow } from "types/CreateOrder";
import { IProcedure } from "types/Procedure";

import {
  buildSelectedProceduresObject,
  buildSelectedProceduresToTable,
} from "./builders";
import { getPreviousQuantity } from "./helpers";
import { ISelectedProcedure } from "./types";

export const autoCompleteFieldHandleOnSelectProcedure = async ({
  e,
  dispatch,
  selectedProcedures,
  procedures,
  providerProcedures,
  rows,
  newValue,
  row,
}: {
  e: AutoCompleteSelectParams;
  dispatch: any;
  selectedProcedures: string[];
  procedures: IProcedure[];
  providerProcedures: any;
  rows: ProcedureRow[];
  newValue: number;
  row?: ProcedureRow;
}) => {
  const theSelectedProcedures: string[] = [...selectedProcedures];
  const previousQuantity = getPreviousQuantity(rows, e.value);

  if (newValue < previousQuantity) {
    if (isEmpty(row)) return;

    const theProcedureRowToDelete: ProcedureRow | undefined = maxBy(
      rows.filter((aRow) => aRow.cptCode === row.cptCode),
      "internalId"
    );

    const rowsToRemove: ProcedureRow[] = rows.filter(
      (aRow) =>
        aRow.internalId === theProcedureRowToDelete?.internalId &&
        aRow.cptCode === theProcedureRowToDelete?.cptCode
    );

    const idsToRemove = rowsToRemove.map((row) => row.id.toString());

    const remainingRows: ProcedureRow[] = rows.filter(
      (aRow) => aRow.internalId !== theProcedureRowToDelete?.internalId
    );

    const newSelectedProcedures = remainingRows.map(
      (row) => `${row.cptCode} - ${row.description}`
    );

    dispatch(removeRows(idsToRemove));
    dispatch(setSelectedProcedures(newSelectedProcedures));
  } else if (newValue > previousQuantity) {
    const selectedProceduresObject = buildSelectedProceduresObject([
      ...theSelectedProcedures,
      e.value,
    ]);

    const rowsToAdd: ProcedureRow[] = buildSelectedProceduresToTable({
      selectedProceduresObject,
      procedures,
      providerProcedures,
      rows,
      row,
    });
    const rowsToAddWithCurrentQuantity = [...rowsToAdd].map((row) => {
      const rowWithCurrentQuantityAndDescription = rows.find(
        (r) =>
          r.cptCode === row.cptCode &&
          r.procedureId === row.procedureId &&
          r.specialty === row.specialty &&
          JSON.stringify(
            [...r.providerDropdown].sort((a, b) => a.id.localeCompare(b.id))
          ) ===
            JSON.stringify(
              [...row.providerDropdown].sort((a, b) => a.id.localeCompare(b.id))
            )
      );
      if (rowWithCurrentQuantityAndDescription) {
        return {
          ...row,
          amount: rowWithCurrentQuantityAndDescription.amount,
          selectedProvider:
            rowWithCurrentQuantityAndDescription.selectedProvider,
          description: rowWithCurrentQuantityAndDescription.description,
          providerProcedureId:
            rowWithCurrentQuantityAndDescription.providerProcedureId,
          toBeEdited: rowWithCurrentQuantityAndDescription.toBeEdited,
        };
      }
      return row;
    });

    dispatch(updateRows(rowsToAddWithCurrentQuantity));
    dispatch(addSelectedProcedures(e.value));
    dispatch(addAutoCompleteValue(e.value));
  }
};

export const autoCompleteHandleOnUnSelectProcedure = ({
  e,
  autoCompleteValue,
  rows,
  dispatch,
}: {
  e: AutoCompleteSelectParams;
  autoCompleteValue: string[];
  rows: ProcedureRow[];
  dispatch: any;
}) => {
  const cptCodeToDelete = buildSelectedProceduresObject([e.value])[0].code;
  const uniqueProviderProceduresRow = filterUniqueObjects(rows, ["internalId"]);
  const repammedAutocompleteValue = autoCompleteValue.map((value) => {
    return buildSelectedProceduresObject([value])[0];
  });

  const procedureIndex = repammedAutocompleteValue.findIndex(
    (currentProcedure) => currentProcedure.code === cptCodeToDelete
  );

  const procedureElement = uniqueProviderProceduresRow[procedureIndex];
  if (procedureElement && procedureIndex !== -1) {
    const filteredProcedures = rows.filter(
      (row) => row.cptCode !== procedureElement.cptCode
    );

    const newAutoComplete = [...autoCompleteValue].filter(
      (_, index) => index !== procedureIndex
    );

    const newSelectedProcedures = filteredProcedures.map(
      (row) => `${row.cptCode} - ${row.description}`
    );
    dispatch(setRows(filteredProcedures));
    dispatch(setAutoCompleteValue(newAutoComplete));
    dispatch(setSelectedProcedures(newSelectedProcedures));
  }
};

export const autoCompleteHandleOnUnSelectProcedureNew = ({
  e,
  autoCompleteValue,
  rows,
  dispatch,
  selectedProcedures,
}: {
  e: AutoCompleteSelectParams;
  autoCompleteValue: string[];
  rows: ProcedureRow[];
  dispatch: any;
  selectedProcedures: string[];
}) => {
  const newAutoCompleteValue = autoCompleteValue.filter(
    (currentSelectedProcedure) => currentSelectedProcedure !== e.value
  );
  const newSelectedProcedures = selectedProcedures.filter(
    (currentSelectedProcedure) => currentSelectedProcedure !== e.value
  );
  const procedureToUnselect = buildSelectedProceduresObject([e.value]);
  const newRows = [...rows].filter(
    (r) => r.cptCode !== procedureToUnselect[0].code
  );
  dispatch(setRows(newRows));
  dispatch(setAutoCompleteValue(newAutoCompleteValue));
  dispatch(setSelectedProcedures(newSelectedProcedures));
};

export const autoCompleteHandleRemoveProcedure = ({
  selectedRow,
  rows,
  selectedProceduresObject,
  dispatch,
}: {
  selectedRow: ProcedureRow;
  rows: ProcedureRow[];
  selectedProceduresObject: ISelectedProcedure[];
  dispatch: any;
}) => {
  const uniqueProceduresSet = new Set();

  const newRows = rows
    ?.map((currentRow) => {
      if (currentRow.providerProcedureId !== selectedRow.providerProcedureId) {
        return { ...currentRow, quantity: 1 };
      }
      return undefined;
    })
    .filter(Boolean) as ProcedureRow[];

  const updateAutoCompleteProcedureList = selectedProceduresObject
    .filter((procedure) => {
      const matchRow = newRows.find((row) => row.cptCode === procedure.code);
      if (!matchRow) {
        return false;
      }
      if (uniqueProceduresSet.has(procedure.cptDescription)) {
        return false;
      }
      uniqueProceduresSet.add(procedure.cptDescription);
      return true;
    })
    .map((procedure) => `${procedure.code} - ${procedure.cptDescription}`);

  const procedureList = !isEmpty(newRows)
    ? updateAutoCompleteProcedureList
    : [];

  const uniqueProviderProceduresRow = filterUniqueObjects(newRows, [
    "internalId",
  ]);
  const newSelectedProcedures = uniqueProviderProceduresRow.map(
    (row) =>
      `${row.cptCode} - ${
        selectedProceduresObject.find((p) => p.code === row.cptCode)
          ?.cptDescription
      }`
  );

  dispatch(setAutoCompleteValue(procedureList));
  dispatch(setSelectedProcedures(newSelectedProcedures));

  dispatch(setRows(newRows));
};

export const quantityChangeHandleOnSelectProcedure = async ({
  e,
  dispatch,
  selectedProcedures,
  procedures,
  providerProcedures,
  rows,
  newValue,
  row,
}: {
  e: AutoCompleteSelectParams;
  dispatch: any;
  selectedProcedures: string[];
  procedures: IProcedure[];
  providerProcedures: any;
  rows: ProcedureRow[];
  newValue: number;
  row?: ProcedureRow;
}) => {
  const theSelectedProcedures: string[] = [...selectedProcedures];
  const previousQuantity = getPreviousQuantity(rows, e.value);
  const existingRows = [...rows];
  const codeToChange = buildSelectedProceduresObject([e.value])[0].code;
  const remappedSelectedProcedures = theSelectedProcedures.map((value) => {
    return buildSelectedProceduresObject([value])[0];
  });

  if (newValue < previousQuantity) {
    if (isEmpty(row)) return;

    let itemsToRemoveCount = previousQuantity - newValue;

    while (itemsToRemoveCount > 0) {
      const theProcedureRowToDelete: ProcedureRow | undefined = maxBy(
        rows.filter((aRow) => aRow.cptCode === row.cptCode),
        "internalId"
      );

      if (!theProcedureRowToDelete) break;

      const rowToRemove: ProcedureRow[] = rows.filter(
        (aRow) =>
          aRow.internalId === theProcedureRowToDelete?.internalId &&
          aRow.cptCode === theProcedureRowToDelete?.cptCode
      );

      const idToRemove = rowToRemove.map((row) => row.id.toString());

      rows = rows.filter(
        (aRow) => aRow.internalId !== theProcedureRowToDelete?.internalId
      );

      itemsToRemoveCount -= 1;

      dispatch(removeRows(idToRemove));
    }

    const exactProcedures = remappedSelectedProcedures
      .filter((item) => item.code === codeToChange)
      .map((item) => `${item.code} - ${item.cptDescription}`);
    const proceduresToKeep = exactProcedures.slice(0, newValue);
    const otherProcedures = [...selectedProcedures].filter(
      (procedure) => !exactProcedures.includes(procedure)
    );
    const newSelectedProcedures = [...proceduresToKeep, ...otherProcedures];

    dispatch(setSelectedProcedures(newSelectedProcedures));
  } else if (newValue > previousQuantity) {
    let itemsToAddCount = newValue - previousQuantity;
    const selectedProcedureValueToAdd = `${codeToChange} - ${
      remappedSelectedProcedures.find((p) => p.code === codeToChange)
        ?.cptDescription
    }`;

    while (itemsToAddCount > 0) {
      const selectedProceduresObject = buildSelectedProceduresObject([
        ...theSelectedProcedures,
        `${codeToChange} - ${
          remappedSelectedProcedures.find((p) => p.code === codeToChange)
            ?.cptDescription
        }`,
      ]);

      const filteredRows = row
        ? existingRows.filter((aRow) => aRow.cptCode === row.cptCode)
        : [];

      const theRowToUseInBuild: ProcedureRow | undefined = row
        ? maxBy(filteredRows, "internalId")
        : undefined;

      const rowToAdd: ProcedureRow[] = buildSelectedProceduresToTable({
        selectedProceduresObject,
        procedures,
        providerProcedures,
        rows: existingRows,
        row: theRowToUseInBuild,
      });

      itemsToAddCount -= 1;
      existingRows.push(...rowToAdd);
      dispatch(updateRows(rowToAdd));
      dispatch(addSelectedProcedures(selectedProcedureValueToAdd));
    }
    dispatch(addAutoCompleteValue(selectedProcedureValueToAdd));
  }
};
