import { AutoCompleteSelectParams } from "primereact/autocomplete";
import { Button } from "primereact/button";
import {
  InputNumber,
  InputNumberValueChangeParams,
} from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { OverlayPanel } from "primereact/overlaypanel";
import { useState, useEffect, useRef } from "react";
import { setRows } from "store/slices/serviceDetails";
import { ProcedureRow } from "types/CreateOrder";
import { IProcedure } from "types/Procedure";
import { IProviderProcedure } from "types/ProviderProcedure";

import { buildSelectedProceduresObject } from "../../builders";
import {
  autoCompleteHandleOnUnSelectProcedureNew,
  quantityChangeHandleOnSelectProcedure,
} from "../../handlers";

export function ProcedureInfoColumn({
  row,
  rows,
  autoCompleteValue,
  dispatch,
  selectedProcedures,
  procedures,
  providerProcedures,
}: {
  row: ProcedureRow;
  rows: ProcedureRow[];
  autoCompleteValue: any;
  dispatch: any;
  selectedProcedures: string[];
  procedures: IProcedure[];
  providerProcedures: IProviderProcedure[];
}) {
  const [showError, setShowError] = useState(!row.description.trim().length);
  const [description, setDescription] = useState("");
  const op = useRef<OverlayPanel>(null);

  useEffect(() => {
    setDescription(row.description);
  }, []);

  const onValueChange = (e: InputNumberValueChangeParams) => {
    const codeToDelete = row.cptCode;
    const repammedAutocompleteValue = autoCompleteValue.map((value: string) => {
      return buildSelectedProceduresObject([value])[0];
    });
    const procedureDefaultDescription = repammedAutocompleteValue.find(
      (value: any) => value.code === codeToDelete
    )?.cptDescription;
    if (e.value === 0) {
      autoCompleteHandleOnUnSelectProcedureNew({
        e: {
          value: `${row.cptCode} - ${procedureDefaultDescription}`,
        } as AutoCompleteSelectParams,
        autoCompleteValue,
        rows,
        dispatch,
        selectedProcedures,
      });
      return;
    }

    quantityChangeHandleOnSelectProcedure({
      e: {
        value: `${row.cptCode} - ${row.description}`,
      } as AutoCompleteSelectParams,
      selectedProcedures,
      procedures,
      providerProcedures,
      rows,
      dispatch,
      newValue: e.value || 1,
      row,
    });
  };
  const onDescriptionChange = (e: any) => {
    const newDescription = e.target.value;
    setDescription(newDescription);
    if (!newDescription.length) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  const onBlurDescription = () => {
    if (row.description.trim() === description.trim()) return;

    const rowsCopy = [...rows];
    const newRows = rowsCopy.map((aRow) => {
      if (
        aRow.cptCode === row.cptCode &&
        aRow.procedureId === row.procedureId
      ) {
        return {
          ...aRow,
          description: description.trim(),
        };
      }
      return aRow;
    });
    dispatch(setRows(newRows));
  };
  return (
    <div className="flex flex-column">
      <div className="flex flex-row gap-1 align-items-center justify-content-between">
        <span className="flex-2 font-bold">
          {row.cptCode} - {row.description}
        </span>
        <Button
          className="p-button-link p-0"
          style={{ minWidth: "fit-content" }}
          onClick={(e) => {
            op?.current?.show(e, e.currentTarget);
          }}
        >
          <p className="small-text">Edit</p>
        </Button>
        <OverlayPanel
          ref={op}
          style={{
            width: "500px",
            maxWidth: "100%",
          }}
          dismissable={false}
        >
          <div className="flex flex-column gap-1 w-full align-items-end">
            <InputTextarea
              value={description}
              defaultValue={row.description}
              autoResize
              rows={1}
              style={{ maxWidth: "100%", width: "100%", minWidth: "100%" }}
              onChange={onDescriptionChange}
            />
            {showError && (
              <span className="text-sm" style={{ color: "#e24c4c" }}>
                CPT description can not be empty
              </span>
            )}
            <div className="flex flex-row gap-2">
              <Button
                onClick={(e) => {
                  op?.current?.toggle(e);
                  setDescription(row.description);
                  setShowError(false);
                }}
                className="mt-2"
              >
                <p className="small-text">Close</p>
              </Button>
              <Button
                onClick={(e) => {
                  onBlurDescription();
                  op?.current?.toggle(e);
                }}
                disabled={showError}
                className="mt-2"
              >
                <p className="small-text">Save</p>
              </Button>
            </div>
          </div>
        </OverlayPanel>
      </div>
      <div
        className="flex flex-1 mt-3 flex-row"
        style={{ alignItems: "center" }}
      >
        <span className="mr-2">QTY:</span>
        <InputNumber
          value={row.quantity}
          inputStyle={{ width: "50px" }}
          inputId="cptCodeQuantity"
          data-testid="cptCodeQuantity_GFEServiceDetails"
          onValueChange={onValueChange}
          min={0}
          max={20}
        />
      </div>
    </div>
  );
}
