import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { LoadingSpinner } from "components/LoadingSpinner";
import { ISPAConfig } from "config/api";
import { ToastContext } from "context/ToastContext";
import { Button } from "primereact/button";
import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useCreateOrderSetupIntentMutation } from "store/queries/order";
import { useUpdatePaymentMethodMutation } from "store/queries/payment";
import { IOrder } from "types/Order/Order";

import { CardPageLayout, ContainerForm } from "../styled";

const getPublishableKey = () => {
  const config = window?.config as ISPAConfig;

  if (!config) {
    throw new Error("Config not found");
  }

  return config.stripe.publishableKey;
};

function CardDetails({
  orderInfo,
  onReturnClick,
}: {
  orderInfo: any;
  onReturnClick: () => void;
}) {
  const elements = useElements();
  const stripe = useStripe();
  const [updatePaymentMethod] = useUpdatePaymentMethodMutation();
  const { current: toastElement } = useContext(ToastContext);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
    });

    if (result.error) {
      console.log(result.error.message);
    } else {
      await updatePaymentMethod({
        orderId: orderInfo.id,
        paymentMethod: result.setupIntent.payment_method,
      })
        .unwrap()
        .then(() => {
          toastElement?.show({
            severity: "success",
            summary: "Payment Method Updated",
            detail: "Stripe payment method updated successfully",
          });
        })
        .catch((error) =>
          toastElement?.show({
            severity: "error",
            summary: "Error",
            detail: `Message: ${error.message}`,
          })
        );
    }
  };

  return (
    <CardPageLayout>
      <ContainerForm>
        <h2 className="mb-2">Fill In New Card Details</h2>
        <div className="sensitive">
          <PaymentElement />
        </div>
        <p className="text-sm text-left text-orange-300">
          {`By clicking Update Card Details, you authorize Clear
        Health to automatically charge the credit card you have provided for the
        amount specified in your Payment Plan for each billing period.`}
        </p>

        <Button
          type="submit"
          data-testid="button-submit-payment-plan"
          className="w-full justify-content-center mt-2  mb-1"
          onClick={handleSubmit}
        >
          <span className="font-bold">Update Card Details</span>
        </Button>
        <div className="flex align-items-center justify-content-center mt-3">
          <Button
            className="p-button-text mb-0 p-button-secondary p-0"
            onClick={onReturnClick}
          >
            <p className="text-xs purple font-medium">Back to details</p>
          </Button>
        </div>
      </ContainerForm>
    </CardPageLayout>
  );
}

export default function EditCardDetails({
  orderInfo,
  onReturnClick,
}: {
  orderInfo: IOrder;
  onReturnClick: () => void;
}) {
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    setStripePromise(loadStripe(getPublishableKey()));
  }, []);

  const [
    createOrderSetupIntent,
    { isError: isStripeError, isLoading: isStripeLoading },
  ] = useCreateOrderSetupIntentMutation();

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    createOrderSetupIntent({ orderId: orderInfo.id })
      .unwrap()
      .then(({ data }: { data: any }) => setClientSecret(data.client_secret));
  }, []);

  if (isStripeError) {
    return <Navigate to="/not-found" />;
  }

  if (isStripeLoading || !clientSecret) {
    return <LoadingSpinner />;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        // HACK TO HIDE THE TERMS TEXT IN STRIPE EMBEDDED ELEMENT
        appearance: {
          rules: {
            ".TermsText": {
              color: "transparent",
              fontSize: "0px",
            },
          },
        },
      }}
    >
      <CardDetails orderInfo={orderInfo} onReturnClick={onReturnClick} />
    </Elements>
  );
}
