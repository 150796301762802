import { Dispatch } from "@reduxjs/toolkit";
import { getRandomNumber } from "helpers/number";
import { filterUniqueObjects } from "helpers/object";
import { isEmpty, orderBy, times } from "lodash";
import { setPriceBreakdownRows } from "store/slices/serviceDetails";
import {
  PriceTableBreakdown,
  PricingBreakdown,
  ProcedureRow,
  ProviderDropdown,
} from "types/CreateOrder";
import { IProcedure } from "types/Procedure";
import { SpecialtyList } from "types/Provider/Provider";
import { IProviderProcedure } from "types/ProviderProcedure";

import { ProcedureInfoColumn } from "./components/Common/ProcedureInfoColumn";
import {
  getNextInternalId,
  getProcedureId,
  getProcedureProvidersWithSameSpecialty,
  getProviderProceduresInfo,
  isSpecialtyAlreadyAdded,
  isValidPrice,
  uniqueByField,
} from "./helpers";
import { ISelectedProcedure } from "./types";

export const buildClearPackageTotalAmount = (rows: ProcedureRow[]): number => {
  return rows.reduce(
    (
      amount: number,
      { selectedProvider }: { selectedProvider: ProviderDropdown }
    ) => amount + selectedProvider.priceInCents,
    0
  );
};

export const buildGFETotalAmount = (rows: PriceTableBreakdown[]): number => {
  return rows.reduce(
    (total: number, { amount }: PriceTableBreakdown) => total + amount,
    0
  );
};

export const buildInternalRowsQuantity = (rows: ProcedureRow[]) => {
  const rowsWithQuantityForTableDisplay = rows.reduce(
    (acc: ProcedureRow[], curr: ProcedureRow) => {
      const existingItemIndex = acc.findIndex(
        (item) =>
          item.selectedProvider.id === curr.selectedProvider.id &&
          item.cptCode === curr.cptCode
      );

      if (existingItemIndex !== -1) {
        acc[existingItemIndex] = {
          ...acc[existingItemIndex],
          quantity: (acc[existingItemIndex].quantity || 0) + 1,
        };
      } else {
        acc.push({ ...curr, quantity: 1 });
      }

      return acc;
    },
    []
  );
  return orderBy(
    rowsWithQuantityForTableDisplay,
    ["internalId", "specialty"],
    ["asc", "asc"]
  );
};

export const buildSelectedProceduresToTable = ({
  selectedProceduresObject,
  procedures,
  providerProcedures,
  rows,
  row,
}: {
  selectedProceduresObject: ISelectedProcedure[];
  procedures: IProcedure[];
  providerProcedures: IProviderProcedure[];
  rows: ProcedureRow[];
  row?: ProcedureRow;
}): ProcedureRow[] => {
  const internalRows: ProcedureRow[] = [];
  // FOR EACH PROCEDURE SELECTED IN AUTO COMPLETE
  if (!isEmpty(row)) {
    const { internalId, cptCode } = row;
    const rowsToAdd = rows.filter(
      (aRow) => aRow.internalId === internalId && aRow.cptCode === cptCode
    );
    const newRows = rowsToAdd.map((aRow) => {
      return {
        ...aRow,
        internalId: getNextInternalId(rows),
        id: getRandomNumber(aRow.providerProcedureId),
      };
    });

    internalRows.push(...newRows);
  } else {
    selectedProceduresObject.forEach((aSelectedProcedure, index) => {
      const providerProcedureId = getProcedureId(
        procedures,
        aSelectedProcedure
      );
      const providerProceduresInfo = getProviderProceduresInfo(
        providerProcedures,
        providerProcedureId
      );
      // FILTER UNIQUE PROVIDER PROCEDURES ROW
      const uniqueProviderProceduresRow = filterUniqueObjects(rows, [
        "internalId",
        "cptCode",
      ]);
      if (!uniqueProviderProceduresRow[index]) {
        let providerDropdown: ProviderDropdown[] = [];
        // FOR EACH PROVIDER PROCEDURE PREVIOUSLY FOUND
        providerProceduresInfo.forEach((providerProcedure) => {
          const providerSpecialty = providerProcedure.provider.specialty;

          // CHECK IF EXISTS SAME SPECIALTY ADDED TO THE INTERNAL ROWS
          if (
            !isSpecialtyAlreadyAdded(
              internalRows,
              index,
              providerProcedureId,
              providerSpecialty
            )
          ) {
            // FIND ALL PROVIDERS WITH SAME SPECIALTY FROM THE DEFAULT PROVIDER PROCEDURE INFO
            const procedureProvidersWithSameSpecialty =
              getProcedureProvidersWithSameSpecialty(
                providerProceduresInfo,
                providerSpecialty
              );
            // FOR EACH PROVIDER WITH SAME SPECIALTY AND IF PRICE IS NOT 0
            // ADD TO THE PROVIDER DROPDOWN
            procedureProvidersWithSameSpecialty.forEach(
              (procedureProvider: {
                priceInCents: number;
                providerId: string;
                provider: { name: string; specialty: SpecialtyList };
              }) => {
                if (isValidPrice(procedureProvider.priceInCents)) {
                  providerDropdown.push({
                    id: procedureProvider.providerId,
                    name: procedureProvider.provider.name,
                    priceInCents: procedureProvider.priceInCents,
                    specialty: procedureProvider.provider.specialty,
                  });
                }
              }
            );
            // ADD TO THE INTERNAL ROWS, ASSUMING SELECTED PROVIDER IS THE FIRST ONE AND
            // AMOUNT IS THE FIRST ONE PRICE IN PROVIDERS DROPDOWN
            // TO DO: ADD LOGIC FOR SELECT PROVIDER AND PROVIDER PRICE BY DRAFT ORDER PROCEDURE
            internalRows.push({
              providerDropdown,
              internalId: index,
              specialty: providerSpecialty,
              selectedProvider: providerDropdown[0],
              amount: providerDropdown[0].priceInCents,
              id: getRandomNumber(providerProcedure.id),
              providerProcedureId: providerProcedure.id,
              procedureId: providerProcedure.procedureId,
              cptCode: aSelectedProcedure.code,
              description: aSelectedProcedure.cptDescription,
              quantity: 1,
              toBeEdited: providerProcedure.toBeEdited,
            });

            providerDropdown = [];
          }
        });
      }
    });
  }

  return internalRows;
};

export const buildFilteredProceduresSuggestions = (
  procedures: IProcedure[]
): string[] => {
  const parsedProcedures = procedures.map(
    (procedure: IProcedure) => `${procedure.cptCode} - ${procedure.description}`
  );
  return [...new Set(parsedProcedures)];
};

export const buildSelectedProceduresObject = (
  selectedProcedures: string[]
): {
  code: string;
  cptDescription: string;
}[] => {
  if (!selectedProcedures) return [];

  return selectedProcedures.map((selectedProcedures) => {
    const procedureCptCode = selectedProcedures.split(" ")[0].trim();
    const procedureDescription = selectedProcedures
      .split(" ")
      .slice(2)
      .join(" ");

    return {
      code: procedureCptCode,
      cptDescription: procedureDescription,
    };
  });
};

export const buildSimpleFirstColumnTemplate = (row: ProcedureRow) => {
  return <span className="font-bold">{row.providerName}</span>;
};

export const buildSelectedProceduresArray = ({
  procedures,
}: {
  procedures: {
    code: number;
    providerProcedureId: number;
    specialty: string;
    cptDescription: string;
  }[];
}) => {
  interface IProcedureInfo {
    code: number;
    quantity: number;
    description: string;
  }
  const selectedProceduresInfo = procedures.reduce(
    (acc: Record<string, IProcedureInfo>, procedure) => {
      const key = `${procedure.code}-${procedure.providerProcedureId}-${procedure.specialty}`;
      if (!acc[key]) {
        acc[key] = {
          code: procedure.code,
          description: procedure.cptDescription,
          quantity: 0,
        };
      }
      acc[key].quantity += 1;
      return acc;
    },
    {}
  );

  const uniqueProcedures = uniqueByField(
    selectedProceduresInfo,
    "code"
  ) as IProcedureInfo[];

  const newProcedureArray = Object.entries(uniqueProcedures).flatMap(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_key, value]) =>
      times(value?.quantity, () => `${value?.code} - ${value?.description}`)
  );

  return newProcedureArray;
};

export const buildFirstColumnWithQuantityTemplate = ({
  row,
  rows,
  autoCompleteValue,
  dispatch,
  selectedProcedures,
  procedures,
  providerProcedures,
}: {
  row: ProcedureRow;
  rows: ProcedureRow[];
  autoCompleteValue: any;
  dispatch: any;
  selectedProcedures: string[];
  procedures: IProcedure[];
  providerProcedures: IProviderProcedure[];
}) => {
  return (
    <ProcedureInfoColumn
      row={row}
      rows={rows}
      autoCompleteValue={autoCompleteValue}
      dispatch={dispatch}
      selectedProcedures={selectedProcedures}
      procedures={procedures}
      providerProcedures={providerProcedures}
    />
  );
};

export const buildSelectedProceduresPriceBreakdownToTable = ({
  priceBreakdown,
  dispatch,
}: {
  priceBreakdown: PricingBreakdown | undefined;
  dispatch: Dispatch;
}) => {
  const updatePatientResponsibilityState =
    priceBreakdown?.procedureBreakdown.map((aPrice) => {
      const { code, cptDescription, owedByPatient, providerName } = aPrice;
      const { coinsurance, deductible } = owedByPatient;

      let description = "";
      if (coinsurance > 0 && deductible > 0) {
        description = "Deductible + Coinsurance";
      } else if (coinsurance > 0) {
        description = "Coinsurance";
      } else if (deductible > 0) {
        description = "Deductible";
      }

      return {
        cptCode: code,
        description: cptDescription,
        amount: owedByPatient.total,
        specialty: description,
        providerName,
      };
    }) || [];

  const updateAdjustmentsState =
    priceBreakdown?.adjustments.map((adjustment) => {
      return {
        cptCode: adjustment.procedure.code.toString(),
        description: adjustment.procedure.cptDescription || "",
        amount: adjustment.discountedPrice,
        specialty: adjustment.discountType,
        providerName: adjustment.procedure.providerName,
      };
    }) || [];

  dispatch(
    setPriceBreakdownRows({
      adjustments: updateAdjustmentsState,
      patientResponsibility: updatePatientResponsibilityState,
    })
  );
};
