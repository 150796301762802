import { useAuth0 } from "@auth0/auth0-react";
import { ItemBar, ItemBarLeft, ItemBarRight } from "components/ItemBar";
import { LoadingSpinner } from "components/LoadingSpinner";
import {
  orderStatusConstants,
  orderStatusMapping,
  orderTypeConstants,
  orderTypeMapping,
} from "constants/order";
import { events } from "constants/tagManager";
import { ToastContext } from "context/ToastContext";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { parseDate } from "helpers/date";
import { centsToDollars, parsePrice } from "helpers/price";
import { useAnalytics } from "hooks/useAnalytics";
import usePrice from "hooks/usePrice";
import { useQuery } from "hooks/useQuery";
import { sizer } from "layout/styles/styled/sizer";
import { debounce } from "lodash";
import { Steps } from "pages/OrderOverview/utils/constants";
import { Button } from "primereact/button";
import { Calendar, CalendarValueType } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTableSortOrderType, DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Paginator, PaginatorPageState } from "primereact/paginator";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useLoginWithOrderIdAndDOBMutation } from "store/queries/auth";
import { useGetOrdersQuery } from "store/queries/order";
import { onPublicTokenChange } from "store/slices/auth";
import { onOrderOverviewStepChange } from "store/slices/order";
import styled from "styled-components";

dayjs.extend(utc);

const StyledButton = styled(Button)`
  gap: ${sizer(2)};
`;

const StyledPageDataTable = styled(DataTable)`
  .p-sortable-column .p-sortable-column-icon {
    display: none !important;
  }
  .p-datatable-tbody > tr > td.p-highlight {
    background-color: unset;
    color: unset;
  }
  .p-sortable-column .p-column-title {
    display: flex;
    gap: ${sizer(1)};
    align-items: center;
    justify-content: space-between;
  }
`;

const STATUS_OPTIONS = [
  {
    value: orderStatusConstants.CANCELED,
    name: orderStatusMapping[orderStatusConstants.CANCELED],
  },
  {
    value: orderStatusConstants.DRAFT,
    name: orderStatusMapping[orderStatusConstants.DRAFT],
  },
  {
    value: orderStatusConstants.EXPIRED,
    name: orderStatusMapping[orderStatusConstants.EXPIRED],
  },
  {
    value: orderStatusConstants.PAID,
    name: orderStatusMapping[orderStatusConstants.PAID],
  },
  {
    value: orderStatusConstants.REFUNDED,
    name: orderStatusMapping[orderStatusConstants.REFUNDED],
  },
  {
    value: orderStatusConstants.SENT_TO_PATIENT,
    name: orderStatusMapping[orderStatusConstants.SENT_TO_PATIENT],
  },
  {
    value: orderStatusConstants.PARTIALLY_PAID,
    name: orderStatusMapping[orderStatusConstants.PARTIALLY_PAID],
  },
  {
    value: orderStatusConstants.NO_BALANCE_DUE,
    name: orderStatusMapping[orderStatusConstants.NO_BALANCE_DUE],
  },
];

const ORDER_TYPE_OPTIONS = [
  {
    value: orderTypeConstants.BUNDLED,
    name: orderTypeMapping[orderTypeConstants.BUNDLED],
  },
  {
    value: orderTypeConstants.ED,
    name: orderTypeMapping[orderTypeConstants.ED],
  },
  {
    value: orderTypeConstants.PATIENT_RESPONSIBILITY,
    name: orderTypeMapping[orderTypeConstants.PATIENT_RESPONSIBILITY],
  },
  {
    value: orderTypeConstants.BARIATRICS,
    name: orderTypeMapping[orderTypeConstants.BARIATRICS],
  },
  {
    value: orderTypeConstants.GFE,
    name: orderTypeMapping[orderTypeConstants.GFE],
  },
];

const transformSearchParam = (value: string | null, isDate = false) => {
  if (!value || value === "") return [];
  if (isDate) {
    return value
      .trim()
      .split(",")
      .map((item) => new Date(item.trim()));
  }
  return value
    .trim()
    .split(",")
    .map((item) => item.trim());
};

export function OrdersTable({ accountId }: { accountId?: string }) {
  const { getClearPriceByOrderType } = usePrice();
  const { loginWithRedirect } = useAuth0();
  const query = useQuery();

  const [login] = useLoginWithOrderIdAndDOBMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { pageViewEvents } = useAnalytics();
  const { current: toastElement } = useContext(ToastContext);

  const [first, setFirst] = useState(0);
  const [rows] = useState(25);
  const [filters, setFilters] = useState({
    status: transformSearchParam(query.get("status")) as string[],
    orderType: transformSearchParam(query.get("orderType")) as string[],
    patientName: query.get("patientName") || "",
    dateOfService: transformSearchParam(query.get("dateOfService"), true) as
      | Date[]
      | [],
    sortOrder: Number(query.get("sortOrder")) as DataTableSortOrderType,
    sortField: query.get("sortField") || (undefined as string | undefined),
  });
  const [debouncedFilters, setDebouncedFilters] = useState(filters);

  useEffect(() => {
    const handler = debounce(() => setDebouncedFilters(filters), 1000);
    handler();
    return () => {
      handler.cancel();
    };
  }, [filters]);

  const {
    data: orders,
    isLoading,
    isFetching,
    isError,
  } = useGetOrdersQuery({
    accountId,
    page: first,
    pageSize: rows,
    ...debouncedFilters,
  });

  useEffect(() => {
    if (isError) loginWithRedirect();
  }, [isError]);

  const parsedOrders = useMemo(() => {
    if (!orders) return [];

    const { data: ordersToParse } = orders;

    const parsed = ordersToParse.map((order) => {
      const { clearPrice } = getClearPriceByOrderType({
        order,
      });
      const { payments } = order;
      const sortedPayments =
        [...payments].sort((a, b) => {
          const dateA = new Date(a.createdAt || new Date(0)).getTime();
          const dateB = new Date(b.createdAt || new Date(0)).getTime();
          return dateA - dateB;
        }) || [];
      const lastPayment = sortedPayments[sortedPayments.length - 1];
      return {
        orderId: order.id,
        status: order.status,
        orderType: order.orderType,
        orderAmount: clearPrice,
        dateOfService: parseDate(order.dateOfService),
        patientName: `${order.patient.firstName} ${order.patient.lastName}`,
        externalId: order.externalId,
        patient: order.patient,
        account: order.account,
        createdAt: parseDate(order.createdAt),
        lastPaymentDate: lastPayment?.createdAt || null,
      };
    });
    return parsed;
  }, [orders]);

  const debouncedFilterChange = debounce((updateFilter) => updateFilter(), 500);

  function handleOnChangeFilter({
    newValue,
    field,
  }: {
    newValue: CalendarValueType | string;
    field: "patientName" | "status" | "orderType" | "dateOfService";
  }) {
    setFilters((prevData) => ({ ...prevData, [field]: newValue }));
    const updateFilter = () => {
      let valueToSet = "";
      if (newValue instanceof Date) {
        valueToSet = newValue.toISOString();
      } else if (Array.isArray(newValue)) {
        if (newValue.every((item) => item instanceof Date)) {
          valueToSet = newValue.map((date) => date.toISOString()).join(",");
        } else if (newValue.every((item) => typeof item === "string")) {
          valueToSet = newValue.join(",");
        } else if (
          newValue.every((item) => item === null || item instanceof Date)
        ) {
          valueToSet = newValue
            .filter((item) => item instanceof Date) // Only convert Date objects
            .map((date) => date.toISOString())
            .join(",");
        }
      } else if (typeof newValue === "string") {
        valueToSet = newValue;
      } else {
        valueToSet = "";
      }
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(field, valueToSet);
      navigate(`?${searchParams.toString()}`, { replace: true });
    };
    debouncedFilterChange(updateFilter);
  }

  const onClick = (row: any) => {
    const currentQueryParams = new URLSearchParams(location.search);
    if (row.status === orderStatusConstants.DRAFT && accountId) {
      currentQueryParams.set("accountId", accountId);
      const queryString = `?${currentQueryParams.toString()}`;
      // NAVIGATE TO THE REVIEW PAGE WITH THE DRAFT ORDER DATA
      navigate(`/orders/review/${row.orderId}${queryString}`);
      return;
    }
    const queryString = `?${currentQueryParams.toString()}`;
    navigate(`/orders/${row.orderId}${queryString}`);
  };

  const onCheckoutClick = (row: any) => {
    login({
      orderId: row.externalId || "",
      dateOfBirth: new Date(row.patient.dateOfBirth).toISOString(),
    })
      .unwrap()
      .then(({ token }: { token: string }) => {
        dispatch(onPublicTokenChange({ token }));
        dispatch(
          onOrderOverviewStepChange({
            step: Steps.ORDER_OVERVIEW,
          })
        );
        pageViewEvents(
          {
            accountName: row.account.name,
            orderType: row.orderType,
            communicationMethod: row.patient.preferredContactMethod,
          },
          events.CLICK_ACCEPT_PAYMENT
        );
        localStorage.setItem("clear_userType", "internal");
        localStorage.setItem("clear_dob", row.patient.dateOfBirth);
        window.open(`/order/${row.externalId}?internalUser=true`, "_blank");
      })
      .catch(() => {
        const detail =
          "Authentication failed. Please check your date of birth.";
        toastElement?.show({
          detail,
          summary: "Error",
          severity: "error",
        });
      });
  };

  const onPageChange = (event: PaginatorPageState) => {
    setFirst(event.first);
  };

  function orderAction(row: any) {
    return (
      <Button
        className="p-button-link"
        data-testid="order_action_OrderTable"
        onClick={() => onClick(row)}
      >
        <p className="small-text">{`${
          row.status === orderStatusConstants.DRAFT ? "Review" : "View"
        } Order`}</p>
      </Button>
    );
  }

  const onSort = (e: any) => {
    const { sortField } = e;
    let newSortOrder = 1 as DataTableSortOrderType;
    let newSortField = sortField;
    if (filters.sortOrder === 1) {
      newSortOrder = -1;
    } else if (filters.sortOrder === -1) {
      newSortOrder = 0;
      newSortField = undefined;
    }

    setFilters((prevData) => ({
      ...prevData,
      sortField: newSortField || undefined,
      sortOrder: newSortOrder,
    }));
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("sortField", newSortField);
    searchParams.set("sortOrder", newSortOrder?.toString() || "");
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  function sortableColumnHeader(
    title: string,
    isSorted: boolean,
    field: string
  ) {
    let iconClass = "pi pi-sort-alt";
    if (isSorted) {
      iconClass =
        filters.sortOrder === 1
          ? "pi pi-sort-amount-up"
          : "pi pi-sort-amount-down";
    }
    return (
      <>
        {title}
        <i
          className={iconClass}
          tabIndex={0}
          role="button"
          aria-label="Sort by field name"
          title="Sort by field name"
          onClick={() => onSort({ sortField: field })}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              onSort({ sortField: field });
            }
          }}
        />
      </>
    );
  }

  function orderCheckoutAction(row: any) {
    if (row.status !== orderStatusConstants.SENT_TO_PATIENT) {
      return null;
    }
    return (
      <Button
        className="p-button-link"
        data-testid="order_action_OrderTable"
        onClick={() => onCheckoutClick(row)}
      >
        <p className="small-text">Checkout</p>
      </Button>
    );
  }

  function getPatientName(row: any) {
    return <div className="sensitive">{row.patientName}</div>;
  }

  if (isLoading || isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <ItemBar>
        <ItemBarLeft>
          <InputText
            data-testid="name_multiselect_OrderTable"
            id="patientSearch"
            value={filters.patientName}
            placeholder="Search for Patient"
            onChange={(e) =>
              handleOnChangeFilter({
                newValue: e.target.value,
                field: "patientName",
              })
            }
          />
          <MultiSelect
            data-testid="type_multiselect_OrderTable"
            display="chip"
            optionLabel="name"
            placeholder="Order type"
            value={filters.orderType}
            options={ORDER_TYPE_OPTIONS}
            onChange={(e) =>
              handleOnChangeFilter({
                newValue: e.target.value,
                field: "orderType",
              })
            }
          />
          <Calendar
            data-testid="range_calendar_OrderTable"
            selectionMode="range"
            placeholder="Date of Service"
            value={filters.dateOfService}
            onChange={(e) => {
              handleOnChangeFilter({
                field: "dateOfService",
                newValue: e.target.value || [],
              });
            }}
          />
          <MultiSelect
            data-testid="status_multiselect_OrderTable"
            display="chip"
            optionLabel="name"
            placeholder="Status"
            value={filters.status}
            options={STATUS_OPTIONS}
            onChange={(e) =>
              handleOnChangeFilter({
                newValue: e.target.value,
                field: "status",
              })
            }
          />
        </ItemBarLeft>
        <ItemBarRight>
          <StyledButton
            data-testid="create_new_order_OrderTable"
            icon="pi pi-plus"
            className="p-button-raised"
            onClick={() => navigate(`/orders/new?accountId=${accountId}`)}
          >
            Create new order
          </StyledButton>
        </ItemBarRight>
      </ItemBar>

      <StyledPageDataTable
        emptyMessage="No orders found."
        stripedRows
        className="p-fluid"
        value={parsedOrders}
        onRowClick={({ data, originalEvent }) =>
          originalEvent.detail > 1 && onClick(data)
        }
        removableSort
        sortMode="single"
        onSort={onSort}
        responsiveLayout="scroll"
      >
        <Column
          field="patientName"
          header="Patient Name"
          headerStyle={{ width: "15%" }}
          body={(row) => getPatientName(row)}
        />
        <Column
          field="orderType"
          header="Order Type"
          body={(row) => orderTypeMapping[row.orderType]}
          headerStyle={{ width: "15%" }}
        />
        <Column
          field="createdAt"
          header="Date Created"
          headerStyle={{ width: "10%" }}
        />
        <Column
          field="dateOfService"
          className={filters.sortField === "dateOfService" ? "p-highlight" : ""}
          header={sortableColumnHeader(
            "Date of Service",
            filters.sortField === "dateOfService",
            "dateOfService"
          )}
          sortable
          headerStyle={{ width: "15%" }}
        />
        <Column
          field="status"
          header="Status"
          body={(row) => orderStatusMapping[row.status]}
          headerStyle={{ width: "10%" }}
        />
        <Column
          field="lastPaymentDate"
          className={
            filters.sortField === "lastPaymentDate" ? "p-highlight" : ""
          }
          header={sortableColumnHeader(
            "Paid/Refunded Date",
            filters.sortField === "lastPaymentDate",
            "lastPaymentDate"
          )}
          body={(row) =>
            row.lastPaymentDate ? parseDate(row.lastPaymentDate) : " "
          }
          sortable
          headerStyle={{ width: "15%" }}
        />
        <Column
          field="orderAmount"
          header="Clear Price for patient"
          body={(row) => parsePrice(centsToDollars(row.orderAmount))}
          headerStyle={{ width: "12%" }}
        />
        <Column
          header=""
          field="viewOrder"
          body={(row) => orderAction(row)}
          headerStyle={{ width: "12%" }}
        />
        <Column
          header=""
          field="checkout"
          body={(row) => orderCheckoutAction(row)}
          headerStyle={{ width: "11%" }}
        />
      </StyledPageDataTable>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={orders?.total}
        onPageChange={onPageChange}
      />
    </div>
  );
}
