import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import styled from "styled-components";

const CustomTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export function CustomTextDialog({
  showCustomTextDialog,
  setShowCustomTextDialog,
  onSendCustomText,
  customText,
  setCustomText,
}: {
  showCustomTextDialog: boolean;
  setShowCustomTextDialog: any;
  onSendCustomText: any;
  customText: string;
  setCustomText: any;
}) {
  const footerComponent = () => {
    return (
      <div>
        <Button
          label="Cancel"
          onClick={() => {
            setShowCustomTextDialog(false);
            setCustomText("");
          }}
          className="p-button-link mr-2"
        />
        <Button
          label="Send"
          onClick={() => {
            onSendCustomText();
            setCustomText("");
            setShowCustomTextDialog(false);
          }}
        />
      </div>
    );
  };
  return (
    <Dialog
      onHide={() => {
        setShowCustomTextDialog(false);
        setCustomText("");
      }}
      visible={showCustomTextDialog}
      header="Send Custom Text"
      footer={footerComponent}
      style={{ minWidth: "30vw" }}
    >
      <div className="flex gap-2">
        <CustomTextContainer>
          <span>Please, insert the text to send to the patient</span>
          <InputTextarea
            value={customText}
            onChange={(e) => setCustomText(e.target.value)}
            rows={4}
            placeholder="Message"
            className="w-full mt-3 mb-1"
          />
          <span> {"For payment link insert this: {{url}}"}</span>
        </CustomTextContainer>
      </div>
    </Dialog>
  );
}
