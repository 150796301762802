import { LoadingSpinner } from "components/LoadingSpinner";
import { events } from "constants/tagManager";
import { parseDate } from "helpers/date";
import { centsToDollars } from "helpers/price";
import { useAnalytics } from "hooks/useAnalytics";
import useDocumentTitle from "hooks/useDocumentTitle";
import usePrice from "hooks/usePrice";
import { useQuery } from "hooks/useQuery";
import { debounce, isEmpty } from "lodash";
import { groupByCodeDescriptionAndCount } from "pages/OrderOverview/components/Overview/helpers";
import { useEffect, useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useGetPublicOrderQuery } from "store/queries/order";
import { IOrder } from "types/Order/Order";
import {
  SpecialtyListDict,
  SpecialtyList,
  IProvider,
} from "types/Provider/Provider";

import EditCardDetails from "./components/EditCardDetails";
import PaymentPlan from "./components/PaymentPlan";
import {
  PageLayout,
  StyledButton,
  StyledHeadlineBanner,
  StyledContainer,
  HorizontalLine,
} from "./styled";

export function Update() {
  useDocumentTitle("Order Credit Card Update");
  const { id: externalId } = useParams();
  const query = useQuery();
  const [showCardDetails, setShowCardDetails] = useState(false);

  const internalUser = query.get("internalUser");

  const navigate = useNavigate();

  const { pageViewEvents } = useAnalytics();

  const { data, isLoading, isError } = useGetPublicOrderQuery(externalId || "");
  const { getClearPriceByOrderType } = usePrice();

  const orderInfo = useMemo(() => {
    return data?.data as IOrder;
  }, [data]);

  const prices = getClearPriceByOrderType({ order: orderInfo });
  const fullPrice = useMemo(() => {
    return centsToDollars(prices.total);
  }, [prices]);
  const clearPrice = useMemo(() => {
    return centsToDollars(prices.clearPrice);
  }, [prices]);

  const procedures = useMemo(() => {
    if (!orderInfo) return [];
    return groupByCodeDescriptionAndCount(orderInfo.serviceDetails.procedures);
  }, [orderInfo]);

  const debouncedPageViewEvents = debounce((order, event: string) => {
    pageViewEvents(
      {
        accountName: order?.accountName,
        orderType: order?.orderType,
        communicationMethod: order?.communicationMethod,
      },
      event
    );
  }, 300);

  useEffect(() => {
    if (isEmpty(data)) return;

    debouncedPageViewEvents(
      {
        accountName: data?.data.account.name,
        orderType: data?.data.orderType,
        communicationMethod: data?.data.patient.preferredContactMethod,
      },
      events.CARD_DETAILS_UPDATED
    );
  }, [data]);

  const publicContact = () => {
    const currentProvider = orderInfo.account
      .providers[0] as unknown as IProvider;

    const isVisible =
      currentProvider.publicContactName &&
      currentProvider.publicContactRole &&
      currentProvider.publicContactPhone;

    return {
      isVisible,
      name: currentProvider.publicContactName,
      role: currentProvider.publicContactRole,
      phone: currentProvider.publicContactPhone,
    };
  };

  if ((!isLoading && !data?.data) || isError) {
    return <Navigate to="/not-found" />;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <PageLayout className="flex flex-column md:mt-3">
      {internalUser && (
        <StyledButton
          icon="pi pi-arrow-left"
          onClick={() => navigate(`/orders/${orderInfo.id}`)}
          className="p-button-text p-button-secondary p-0"
        >
          <p className="text-xs">Back to order</p>
        </StyledButton>
      )}
      {showCardDetails && (
        <EditCardDetails
          orderInfo={orderInfo}
          onReturnClick={() => setShowCardDetails(false)}
        />
      )}
      {!showCardDetails && (
        <>
          <StyledHeadlineBanner className=" text-center font-semibold border-round-md p-3">
            Your order details
          </StyledHeadlineBanner>

          <StyledContainer>
            <div className="flex justify-content-between mb-3 mt-1 gap-2">
              <p className="black font-semibold gray uppercase">
                Services Provided
              </p>
              <p className="gray font-medium text-right">
                Visit Date {parseDate(orderInfo.dateOfService)}
              </p>
            </div>
            <div className="flex flex-column border-round-sm mb-3 gap-3">
              {procedures.map((currentProcedure: any) => (
                <div
                  key={`${currentProcedure.code}_${currentProcedure.cptDescription}`}
                >
                  <p className=" font-medium mt-0">
                    {`${currentProcedure.code} - ${
                      currentProcedure.cptDescription
                    }${
                      currentProcedure.providers[0]?.quantity
                        ? `: Quantity ${currentProcedure.providers[0]?.quantity}`
                        : ""
                    }`}
                  </p>

                  {currentProcedure.providers.map(
                    (provider: {
                      name: string;
                      specialty: string;
                      quantity: number;
                    }) => (
                      <p className=" mt-0">
                        {`${provider.name}, ${
                          SpecialtyListDict[provider.specialty as SpecialtyList]
                        }`}
                      </p>
                    )
                  )}
                </div>
              ))}
            </div>
            <HorizontalLine />
            <PaymentPlan
              orderInfo={orderInfo}
              fullPrice={fullPrice}
              clearPrice={clearPrice}
              onShowCardDetails={() => setShowCardDetails(true)}
            />
          </StyledContainer>
          <div className="flex justify-content-center gap-4 mt-4">
            {publicContact().isVisible && (
              <div className="text-center gap-1 flex flex-column">
                <p className=" gray font-bold">{publicContact().name}</p>
                <p className=" gray"> {publicContact().role}</p>
                <p className=" gray"> {publicContact().phone}</p>
              </div>
            )}

            <div className="text-center gap-1 flex flex-column">
              <p className=" gray font-bold">Brooke Leblanc</p>
              <p className=" gray">Clear Health Support</p>
              <p className=" gray">888-918-2522</p>
            </div>
          </div>
        </>
      )}
    </PageLayout>
  );
}
