import { orderStatusConstants, orderTypeConstants } from "constants/order";
import { useMemo } from "react";
import { IOrder } from "types/Order/Order";
import { IProvider } from "types/Provider/Provider";

export const useOrder = (order: IOrder) => {
  const isDefaultOrderType = useMemo(() => {
    return (
      order.orderType === orderTypeConstants.BUNDLED ||
      order.orderType === orderTypeConstants.BARIATRICS ||
      order.orderType === orderTypeConstants.ED
    );
  }, [order]);

  const isGFE = useMemo(() => {
    return order.orderType === orderTypeConstants.GFE;
  }, [order]);

  const isPatientResponsibility = useMemo(() => {
    return order.orderType === orderTypeConstants.PATIENT_RESPONSIBILITY;
  }, [order]);

  const isStatusSentToPatient =
    order.status === orderStatusConstants.SENT_TO_PATIENT;

  const payment = order.payments.find(
    (payment) =>
      payment.status === orderStatusConstants.PAID &&
      payment.preventStripeTransfers
  );

  const isBariatricsNotReadyForTransfer =
    order.status === orderStatusConstants.PAID &&
    order.orderType === orderTypeConstants.BARIATRICS &&
    !!payment &&
    payment.preventStripeTransfers;

  const isStatusPaid = order.status === orderStatusConstants.PAID;

  const isStatusExpired = order.status === orderStatusConstants.EXPIRED;

  const mainFacility = useMemo(() => {
    const mainFacility = order.account.providers.find(
      (aProvider) => aProvider.mainFacility === true
    ) as IProvider;

    return mainFacility;
  }, [order.account]);

  return {
    isDefaultOrderType,
    isGFE,
    isPatientResponsibility,
    isStatusSentToPatient,
    isBariatricsNotReadyForTransfer,
    isStatusExpired,
    mainFacility,
    payment,
    isStatusPaid,
  };
};
