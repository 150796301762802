import { orderHsitoryTypeConstants } from "constants/orderHistory";
import { ToastContext } from "context/ToastContext";
import { useUserWithAccount } from "context/UserAccountProvider";
import { parseDateInCST, parseTimeInCST } from "helpers/date";
import { centsToDollars, parsePrice } from "helpers/price";
import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Timeline } from "primereact/timeline";
import { useMemo, useState, useContext } from "react";
import { useCreateOrderHistoryMutation } from "store/queries/orderHistory";
import styled from "styled-components";
import { IOrderHistory } from "types/Order/Order";

import { AddNoteDialog } from "./components/AddNoteDialog";

const StyledCard = styled(Card)`
  margin-bottom: ${sizer(2)};
`;

const StyledTimeline = styled(Timeline)`
  .p-timeline-event-opposite {
    flex: 0 0;
    min-width: 178px;
    padding-top: 6px !important;
  }
  .p-timeline-event-content {
    padding-top: 6px !important;
  }
`;

const StyledShowMore = styled.p`
  color: var(--color-purple);
  margin-top: 30px;
`;

const descriptionRenderer: { [key: string]: string } = {
  Message1: "Message 1",
  Message2: "Message 2",
  Message3: "Message 3",
  Receipt: "Receipt",
};

const generateOrderHistoryLine = (item: IOrderHistory) => {
  const isPaid = !!(item.type === "Paid");
  const isPartiallyPaid = !!(item.type === "PartiallyPaid");
  const isInstallmentPaid = !!(item.type === "InstallmentPaid");
  const isCommunicationSent = !!(
    item.type === "EmailSent" ||
    item.type === "TextMessageSent" ||
    item.type === "EmailResent" ||
    item.type === "TextMessageResent"
  );
  const isTransfer = item.type === "Transfer";
  const isTransferBlocked = !!(
    item.type === "PaymentMarkedBlockedForTransfers"
  );

  const transferText = ` ${parsePrice(
    centsToDollars(item.amountInCents || 0)
  )} From ${item.fromProvider?.name || "Clear Health"} To ${
    item.toProvider?.name ||
    `${item.toPatient?.firstName} ${item.toPatient?.lastName}`
  }`;
  return (
    <span>
      {parseDateInCST(item.createdAt)} {parseTimeInCST(item.createdAt)}{" "}
      {isTransfer && transferText}
      {(isPartiallyPaid || isInstallmentPaid) &&
        item.amountInCents !== null &&
        item.amountInCents !== undefined &&
        ` Amount ${parsePrice(centsToDollars(item.amountInCents))}`}{" "}
      {item.user && !isPaid && !isPartiallyPaid && ` by ${item.user.name}`}{" "}
      {item.user &&
        (isPaid || isPartiallyPaid) &&
        ` Collected by ${item.user.name}`}{" "}
      {item.thirdParty && `by ${item.thirdParty}`}{" "}
      {isCommunicationSent && item.description
        ? descriptionRenderer[item.description]
        : ""}
      {item.note && ` Note: ${item.note}`}
      {isTransferBlocked && `Transfer Blocked by ${item.user?.name}`}
    </span>
  );
};

const custimizedMarker = (item: IOrderHistory) => {
  const iconClasses: { [key: string]: string } = {
    Created: "pi pi-check",
    Updated: "pi pi-pencil",
    Canceled: "pi pi-times",
    Paid: "pi pi-dollar",
    Transfer: "pi pi-sync",
    PartiallyPaid: "pi pi-dollar",
    InstallmentPaid: "pi pi-dollar",
    EmailSent: "pi pi-envelope",
    TextMessageSent: "pi pi-comment",
    EmailResent: "pi pi-envelope",
    TextMessageResent: "pi pi-comment",
    EstimateOpened: "pi pi-eye",
    NotesAdded: "pi pi-file-edit",
    NoteToPdf: "pi pi-file-edit",
    Redone: "pi pi-refresh",
    CreatedAsDraft: "pi pi-check",
    Approved: "pi pi-check",
    PaymentMarkedBlockedForTransfers: "pi pi-ban",
  };
  return (
    <div
      className="flex w-2rem h-2rem align-items-center justify-content-center border-2 border-circle"
      style={{
        borderColor: "var(--color-mint-5)",
        color: "var(--color-mint-5)",
      }}
    >
      <i className={iconClasses[item.type]} />
    </div>
  );
};

const typeRenderer: { [key: string]: string } = {
  PartiallyPaid: "Partially Paid",
  InstallmentPaid: "Auto Paid",
  EmailSent: "Email Sent",
  TextMessageSent: "Text Message Sent",
  EmailResent: "Email Resent",
  TextMessageResent: "Text Message Resent",
  EstimateOpened: "Estimate Opened",
  NotesAdded: "Note Added",
  NoteToPdf: "Note Added to PDF",
  CreatedAsDraft: "Created as Draft",
  PaymentMarkedBlockedForTransfers: "Transfer Blocked",
};

function OrderHistory({
  orderHistories,
  orderCreated,
  orderId,
  onUpdateOrder,
}: {
  orderHistories: IOrderHistory[];
  orderCreated: string;
  orderId: string;
  onUpdateOrder: () => void;
}) {
  const [showMore, setShowMore] = useState(false);
  const [note, setNote] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [createOrderHistory] = useCreateOrderHistoryMutation();
  const { current: toastElement } = useContext(ToastContext);
  const { userInfo } = useUserWithAccount();
  const orderHistoryList = useMemo(() => {
    const orderHistoryListUpdated = [...orderHistories];
    const hasCreatedType = orderHistories.some(
      (item) => item.type === "Created"
    );
    const isOldOrder = new Date(orderCreated) < new Date("2024-12-01");
    if (!hasCreatedType && isOldOrder) {
      orderHistoryListUpdated.unshift({
        id: "",
        type: "Created",
        createdAt: orderCreated,
        orderId,
      });
    }
    return orderHistoryListUpdated;
  }, [orderHistories, orderId, orderCreated]);

  const onAddNote = () => {
    try {
      createOrderHistory({
        orderId,
        type: orderHsitoryTypeConstants.NotesAdded,
        note: note?.trim()?.length ? note.trim() : undefined,
        userId: userInfo?.id,
      })
        .unwrap()
        .then(async () => {
          onUpdateOrder();
          toastElement?.show({
            summary: "Success!",
            severity: "success",
            detail: "Order Note has been added successfully.",
          });
        });
    } catch (error) {
      toastElement?.show({
        severity: "error",
        detail: "Try again later.",
        summary: "Something went wrong.",
      });
    }
    setDialogVisible(false);
    setNote("");
  };

  const header = () => {
    return (
      <div className="flex flex-column col-12 align-items-end">
        <div>
          <Button
            label="Add Note"
            icon="pi pi-plus"
            onClick={() => {
              setDialogVisible(true);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <AddNoteDialog
        note={note}
        setNote={setNote}
        showDialog={dialogVisible}
        setShowDialog={setDialogVisible}
        onAddNote={onAddNote}
      />
      <StyledCard title="Order history" header={header()}>
        <StyledTimeline
          value={showMore ? orderHistoryList : orderHistoryList.slice(0, 3)}
          content={(item) => generateOrderHistoryLine(item)}
          opposite={(item) => typeRenderer[item.type] || item.type}
          marker={(item) => custimizedMarker(item)}
        />
        {orderHistoryList?.length > 3 && (
          <StyledShowMore
            className="small-text purple flex align-items-center cursor-pointer mb-4"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? (
              <>
                Show Less <i className="pi pi-angle-up ml-1" />
              </>
            ) : (
              <>
                Show More <i className="pi pi-angle-down ml-1" />
              </>
            )}
          </StyledShowMore>
        )}
      </StyledCard>
    </>
  );
}

export default OrderHistory;
