import { DataTableSortOrderType } from "primereact/datatable";

export const buildURLforOrders = ({
  accountId,
  page,
  pageSize,
  status,
  orderType,
  patientName,
  dateOfService,
  sortOrder,
  sortField,
}: {
  accountId?: string;
  page?: number;
  pageSize?: number;
  status?: string[];
  orderType?: string[];
  patientName?: string;
  dateOfService?: Date[];
  sortOrder: DataTableSortOrderType;
  sortField: string | undefined;
}) => {
  const baseUrl = `/orders${accountId ? `/account/${accountId}` : ""}`;
  const pagination =
    page !== undefined && pageSize !== undefined
      ? `page=${page}&pageSize=${pageSize}`
      : null;
  const filtrationParams = [
    !status || !status.length ? null : `status=${status.join(",")}`,
    !orderType || !orderType.length ? null : `orderType=${orderType.join(",")}`,
    !patientName || !patientName.length ? null : `patientName=${patientName}`,
    !dateOfService || !dateOfService.length
      ? null
      : `dateOfService=${dateOfService.join(",")}`,
    !sortField || !sortOrder
      ? null
      : `sortField=${sortField}&sortOrder=${sortOrder === 1 ? "asc" : "desc"}`,
  ];
  const queryParams = [pagination, ...filtrationParams]
    .filter(Boolean)
    .join("&");
  const finalUrl = `${baseUrl}${queryParams ? `?${queryParams}` : ""}`;
  return finalUrl;
};
